const countries = [
  {
    name: "Afrique du Sud",
    code: "ZA",
  },
  {
    name: "Albanie",
    code: "AL",
  },
  {
    name: "Allemagne",
    code: "DE",
  },
  {
    name: "Angola",
    code: "AO",
  },
  {
    name: "Arabie saoudite",
    code: "SA",
  },
  {
    name: "Argentine",
    code: "AR",
  },
  {
    name: "Australie",
    code: "AU",
  },
  {
    name: "Autriche",
    code: "AT",
  },
  {
    name: "Bahreïn",
    code: "BH",
  },
  {
    name: "Bangladesh",
    code: "BD",
  },
  {
    name: "Barbade",
    code: "BB",
  },
  {
    name: "Belgique",
    code: "BE",
  },
  {
    name: "Belize",
    code: "BZ",
  },
  {
    name: "Bénin",
    code: "BJ",
  },
  {
    name: "Bolivie",
    code: "BO",
  },
  {
    name: "Bosnie-Herzégovine",
    code: "BA",
  },
  {
    name: "Brésil",
    code: "BR",
  },
  {
    name: "Bulgarie",
    code: "BG",
  },
  {
    name: "Burkina Faso",
    code: "BF",
  },
  {
    name: "Cambodge",
    code: "KH",
  },
  {
    name: "Cameroun",
    code: "CM",
  },
  {
    name: "Canada",
    code: "CA",
  },
  {
    name: "Chili",
    code: "CL",
  },
  {
    name: "Chine",
    code: "CN",
  },
  {
    name: "Chypre",
    code: "CY",
  },
  {
    name: "Colombie",
    code: "CO",
  },
  {
    name: "Corée du Sud",
    code: "KR",
  },
  {
    name: "Costa Rica",
    code: "CR",
  },
  {
    name: "Côte d'Ivoire",
    code: "CI",
  },
  {
    name: "Croatie",
    code: "HR",
  },
  {
    name: "Danemark",
    code: "DK",
  },
  {
    name: "Égypte",
    code: "EG",
  },
  {
    name: "El Salvador",
    code: "SV",
  },
  {
    name: "Émirats arabes unis",
    code: "AE",
  },
  {
    name: "Équateur",
    code: "EC",
  },
  {
    name: "Espagne",
    code: "ES",
  },
  {
    name: "Estonie",
    code: "EE",
  },
  {
    name: "États-Unis",
    code: "US",
  },
  {
    name: "Finlande",
    code: "FI",
  },
  {
    name: "France",
    code: "FR",
  },
  {
    name: "Géorgie",
    code: "GE",
  },
  {
    name: "Ghana",
    code: "GH",
  },
  {
    name: "Grèce",
    code: "GR",
  },
  {
    name: "Grenade",
    code: "GD",
  },
  {
    name: "Guatemala",
    code: "GT",
  },
  {
    name: "Guinée",
    code: "GN",
  },
  {
    name: "Honduras",
    code: "HN",
  },
  {
    name: "Hong Kong",
    code: "HK",
  },
  {
    name: "Hongrie",
    code: "HU",
  },
  {
    name: "Îles Caïmans",
    code: "KY",
  },
  {
    name: "Inde",
    code: "IN",
  },
  {
    name: "Indonésie",
    code: "ID",
  },
  {
    name: "Irlande",
    code: "IE",
  },
  {
    name: "Islande",
    code: "IS",
  },
  {
    name: "Israël",
    code: "IL",
  },
  {
    name: "Italie",
    code: "IT",
  },
  {
    name: "Jamaïque",
    code: "JM",
  },
  {
    name: "Jordanie",
    code: "JO",
  },
  {
    name: "Kazakhstan",
    code: "KZ",
  },
  {
    name: "Kenya",
    code: "KE",
  },
  {
    name: "Kosovo",
    code: "XK",
  },
  {
    name: "Koweït",
    code: "KW",
  },
  {
    name: "Lettonie",
    code: "LV",
  },
  {
    name: "Lituanie",
    code: "LT",
  },
  {
    name: "Luxembourg",
    code: "LU",
  },
  {
    name: "Macédoine du Nord",
    code: "MK",
  },
  {
    name: "Malaisie",
    code: "MY",
  },
  {
    name: "Malte",
    code: "MT",
  },
  {
    name: "Maroc",
    code: "MA",
  },
  {
    name: "Mexique",
    code: "MX",
  },
  {
    name: "Moldavie",
    code: "MD",
  },
  {
    name: "Monténégro",
    code: "ME",
  },
  {
    name: "Myanmar (Birmanie)",
    code: "MM",
  },
  {
    name: "Nicaragua",
    code: "NI",
  },
  {
    name: "Nigeria",
    code: "NG",
  },
  {
    name: "Norvège",
    code: "NO",
  },
  {
    name: "Nouvelle-Zélande",
    code: "NZ",
  },
  {
    name: "Ouganda",
    code: "UG",
  },
  {
    name: "Ouzbékistan",
    code: "UZ",
  },
  {
    name: "Pakistan",
    code: "PK",
  },
  {
    name: "Panama",
    code: "PA",
  },
  {
    name: "Paraguay",
    code: "PY",
  },
  {
    name: "Pays-Bas",
    code: "NL",
  },
  {
    name: "Pérou",
    code: "PE",
  },
  {
    name: "Philippines",
    code: "PH",
  },
  {
    name: "Pologne",
    code: "PL",
  },
  {
    name: "Porto Rico",
    code: "PR",
  },
  {
    name: "Portugal",
    code: "PT",
  },
  {
    name: "Qatar",
    code: "QA",
  },
  {
    name: "République dominicaine",
    code: "DO",
  },
  {
    name: "République tchèque",
    code: "CZ",
  },
  {
    name: "Roumanie",
    code: "RO",
  },
  {
    name: "Royaume-Uni",
    code: "GB",
  },
  {
    name: "Serbie",
    code: "RS",
  },
  {
    name: "Seychelles",
    code: "SC",
  },
  {
    name: "Singapour",
    code: "SG",
  },
  {
    name: "Slovaquie",
    code: "SK",
  },
  {
    name: "Slovénie",
    code: "SI",
  },
  {
    name: "Sri Lanka",
    code: "LK",
  },
  {
    name: "Suède",
    code: "SE",
  },
  {
    name: "Suisse",
    code: "CH",
  },
  {
    name: "Taïwan",
    code: "TW",
  },
  {
    name: "Thaïlande",
    code: "TH",
  },
  {
    name: "Trinité-et-Tobago",
    code: "TT",
  },
  {
    name: "Tunisie",
    code: "TN",
  },
  {
    name: "Ukraine",
    code: "UA",
  },
  {
    name: "Uruguay",
    code: "UY",
  },
  {
    name: "Venezuela",
    code: "VE",
  },
  {
    name: "Vietnam",
    code: "VN",
  },
  {
    name: "Zimbabwe",
    code: "ZW",
  },
];

async function getSpecificCountries() {
  try {
    const formattedCountries = countries.map((country) => {
      return {
        label: country.name,
        value: country.name,
        code: country.code,
      };
    });

    // Trier les pays par ordre alphabétique
    formattedCountries.sort((a, b) => a.label.localeCompare(b.label));

    return formattedCountries;
  } catch (error) {
    console.error("Erreur lors de la récupération des pays:", error);
    return [];
  }
}

// Récupérer les pays et les stocker dans une variable nommée "pays"
let pays;

getSpecificCountries()
  .then((countries) => {
    pays = countries;
  })
  .catch((error) => {
    console.error("Une erreur s'est produite:", error);
  });

const zones = [
  { label: "ILE DE FRANCE (01)", value: "01" },
  { label: "NORD OUEST (02)", value: "02" },
  { label: "NORD EST (03)", value: "03" },
  { label: "SUD EST (04)", value: "04" },
  { label: "SUD OUEST (05)", value: "05" },
  { label: "NATIONAL (09)", value: "09" },
  { label: "GUADELOUPE (05 90)", value: "0590" },
  { label: "RÉUNION (02 62)", value: "0262" },
  { label: "MARTINIQUE (05 96)", value: "0596" },
  { label: "GUYANE (05 94)", value: "0594" },
];

const typeNumber = [
  {
    label: "Numéro Standard",
    value: "standard",
    isOffert: false,
    isInclude: true,
  },
  { label: "Numéro Argent", value: "argent", isOffert: true },
  { label: "Numéro OR", value: "or", isOffert: true },
  { label: "Personnaliser un numéro", value: "editNumber", isOffert: false },
];

const numbersOptions = {
  "01": ["01 87 20", "01 88 84", "01 89 27"],
  "02": ["02 20 04", "02 59 22", "02 79 93"],
  "03": ["03 39 68", "03 56 57", "03 76 21"],
  "04": ["04 12 10", "04 20 56", "04 23 16", "04 51 20", "04 85 62"],
  "05": ["05 25 32", "05 32 96", "05 48 28"],
  "09": ["09 72 01", "09 72 79"],
  "0590": ["05 90 19"],
  "0262": ["02 62 07"],
  "0596": ["05 96 32"],
  "0594": ["05 94 97"],
};

const editNumberTypeMessages = {
  standard: "",
  argent:
    "Le numéro que vous avez demandé est un numéro Argent. En le choisissant, vous serez facturé de 49€HT",
  or: "Le numéro que vous avez demandé est un numéro OR. En le choisissant, vous serez facturé de 99€HT",
};

const freeTrial = [
  "Essai gratuit et sans engagement annulable à tout moment",
  "Standard téléphonique 100% configurable en ligne",
  "Renvoi d’appels vers fixes et mobiles France Métropolitaine + DOM",
];

export {
  pays,
  zones,
  typeNumber,
  numbersOptions,
  editNumberTypeMessages,
  freeTrial,
};
