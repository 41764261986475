const sendEmail = async(params) => {
    try {

        const request = await fetch(`${process.env.REACT_APP_DOMAINE}/email`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params)
        })

        return request.json()

    } catch(err) {
        throw err
    }
}

export default sendEmail