import { useEffect, useState } from "react";

import "../../assets/css/App.css";

import Header from "../../composants/Header";
import ProgressBar from "../../composants/ProgressBar";
import Title from "../../composants/Title";
import Button from "../../composants/Button";
import InfoContainer from "../../composants/Config/Info";
import routes from "../../router/routes";
import { getCommandeByConfigToken } from "../../services/commande";
import { useSearchParams } from "react-router-dom";

const Infos = () => {
  const [searchParams] = useSearchParams();
  const configToken = searchParams.get("token");
  const [isLoading, setIsLoading] = useState(true);
  const [link, setLink] = useState("");
  const checkLink = () => {
    let localValue = sessionStorage.getItem("choix_menu");

    sessionStorage.removeItem("paiement_success");

    if (localValue === null) {
    } else {
      if (link === "") {
        if (localValue === "1") {
          setLink(routes.configRenvoi);
        } else if (localValue === "2") {
          setLink(routes.configRenvoi);
        } else if (localValue === "3") {
          setLink(routes.configChoix);
        }
      }
    }

    setTimeout(checkLink, 1000);
  };

  useEffect(() => {
    checkLink();
  });

  useEffect(() => {
    if (Boolean(configToken))
      getCommandeByConfigToken({ configToken })
        .then((data) =>
          window.localStorage.setItem("commandeToken", JSON.stringify(data))
        )
        .finally(() => setIsLoading(false));
    else setIsLoading(false);
  }, [configToken]);

  if (isLoading) return null;
  return (
    <div className="Home">
      <Header />
      {!!!isLoading && (
        <div className="body-config">
          <div className="container-config">
            <ProgressBar before={0} percentage={25} etape={1} />
            <div style={{ paddingTop: "30px" }}></div>
            <Title title={"Réglage de votre standard"} />
            <InfoContainer />
            <Button lien={link} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Infos;
