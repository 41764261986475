import sendEmail from "../../email/sendEmail";

const sendEmailFinish = async (client) => {
  console.log("whaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaat");
  try {
    const num_buy = sessionStorage.getItem("num_buy");
    const nom_societe = sessionStorage.getItem("nom_societe");
    const email_contact = sessionStorage.getItem("email_contact");
    const choix_menu = sessionStorage.getItem("choix_menu");

    const mandat_infos = JSON.parse(sessionStorage.getItem("mandat_infos"));

    // 1 et 2
    const num_renvoi = sessionStorage.getItem("numero_renvoi");

    // 2 et 3
    const menu_redac = JSON.parse(sessionStorage.getItem("menu_redac"));
    const menu_picto = JSON.parse(sessionStorage.getItem("menu_picto"));
    const menu_hours = JSON.parse(sessionStorage.getItem("menu_hours"));
    const menu_email = JSON.parse(sessionStorage.getItem("menu_email"));
    // 3
    const config_choix_number = JSON.parse(
      sessionStorage.getItem("config_choix_number")
    );

    const config_choix_1 = JSON.parse(sessionStorage.getItem("config_choix_1"));
    const config_choix_2 = JSON.parse(sessionStorage.getItem("config_choix_2"));
    const config_choix_3 = JSON.parse(sessionStorage.getItem("config_choix_3"));

    const config_numero_1 = JSON.parse(
      sessionStorage.getItem("choix_numero_1")
    );
    const config_numero_2 = JSON.parse(
      sessionStorage.getItem("choix_numero_2")
    );
    const config_numero_3 = JSON.parse(
      sessionStorage.getItem("choix_numero_3")
    );

    switch (choix_menu) {
      case "1":
        if (num_renvoi !== "" && num_renvoi !== null) {
          await sendEmail({
            email: client.email,

            nom_prenom: `${mandat_infos.nom} ${mandat_infos.prenom}`,
            numero: num_buy,
            societe: mandat_infos.societe,
            email_contact: email_contact,
            relation_unique: num_renvoi,

            iban: mandat_infos.iban,
            nom_societe: mandat_infos.societe,
            pays: mandat_infos.pays,
            ville: mandat_infos.ville,
            adresse: mandat_infos.adresse,
            postal: mandat_infos.postal,

            emailType: 2,
            config_choix: 1,
          });
          sessionStorage.setItem("config_send", true);
        } else {
          return false;
        }

        break;

      case "2":
        if (
          num_renvoi !== "" &&
          num_renvoi !== null &&
          menu_redac.accueil !== "" &&
          menu_redac.accueil !== null &&
          menu_picto.picto1 !== "" &&
          menu_picto.picto1 !== null &&
          menu_picto.picto2 !== "" &&
          menu_picto.picto2 !== null &&
          menu_picto.picto3 !== "" &&
          menu_picto.picto3 !== null
        ) {
          sendEmail({
            email: client.email,

            nom_prenom: `${mandat_infos.nom} ${mandat_infos.prenom}`,
            numero: num_buy,
            societe: mandat_infos.societe,
            email_contact: email_contact,
            relation_unique: num_renvoi,

            message_accueil: menu_redac.accueil,
            message_attente: menu_redac.attente,
            message_fermeture: menu_redac.fermeture,

            lundi:
              menu_picto.picto1 === 1
                ? menu_hours[0].lundi1.select === true
                  ? menu_picto.picto2 === 1
                    ? `${menu_hours[0].lundi1.input1}h${menu_hours[0].lundi1.input2} à ${menu_hours[0].lundi1.input3}:${menu_hours[0].lundi1.input4}`
                    : `${menu_hours[0].lundi1.input1}h${menu_hours[0].lundi1.input2} à ${menu_hours[0].lundi1.input3}:${menu_hours[0].lundi1.input4} - ${menu_hours[0].lundi1.input5}:${menu_hours[0].lundi1.input6} à ${menu_hours[0].lundi1.input7}:${menu_hours[0].lundi1.input8}`
                  : "Fermé "
                : "",
            mardi:
              menu_picto.picto1 === 1
                ? menu_hours[1].mardi1.select === true
                  ? menu_picto.picto2 === 1
                    ? `${menu_hours[1].mardi1.input1}h${menu_hours[1].mardi1.input2} à ${menu_hours[1].mardi1.input3}:${menu_hours[1].mardi1.input4}`
                    : `${menu_hours[1].mardi1.input1}h${menu_hours[1].mardi1.input2} à ${menu_hours[1].mardi1.input3}:${menu_hours[1].mardi1.input4} - ${menu_hours[1].mardi1.input5}:${menu_hours[1].mardi1.input6} à ${menu_hours[1].mardi1.input7}:${menu_hours[1].mardi1.input8}`
                  : "Fermé "
                : "",
            mercredi:
              menu_picto.picto1 === 1
                ? menu_hours[2].mercredi1.select === true
                  ? menu_picto.picto2 === 1
                    ? `${menu_hours[2].mercredi1.input1}h${menu_hours[2].mercredi1.input2} à ${menu_hours[2].mercredi1.input3}:${menu_hours[2].mercredi1.input4}`
                    : `${menu_hours[2].mercredi1.input1}h${menu_hours[2].mercredi1.input2} à ${menu_hours[2].mercredi1.input3}:${menu_hours[2].mercredi1.input4} - ${menu_hours[2].mercredi1.input5}:${menu_hours[2].mercredi1.input6} à ${menu_hours[2].mercredi1.input7}:${menu_hours[2].mercredi1.input8}`
                  : "Fermé "
                : "",
            jeudi:
              menu_picto.picto1 === 1
                ? menu_hours[3].jeudi1.select === true
                  ? menu_picto.picto2 === 1
                    ? `${menu_hours[3].jeudi1.input1}h${menu_hours[3].jeudi1.input2} à ${menu_hours[3].jeudi1.input3}:${menu_hours[3].jeudi1.input4}`
                    : `${menu_hours[3].jeudi1.input1}h${menu_hours[3].jeudi1.input2} à ${menu_hours[3].jeudi1.input3}:${menu_hours[3].jeudi1.input4} - ${menu_hours[3].jeudi1.input5}:${menu_hours[3].jeudi1.input6} à ${menu_hours[3].jeudi1.input7}:${menu_hours[3].jeudi1.input8}`
                  : "Fermé "
                : "",
            vendredi:
              menu_picto.picto1 === 1
                ? menu_hours[4].vendredi1.select === true
                  ? menu_picto.picto2 === 1
                    ? `${menu_hours[4].vendredi1.input1}h${menu_hours[4].vendredi1.input2} à ${menu_hours[4].vendredi1.input3}:${menu_hours[4].vendredi1.input4}`
                    : `${menu_hours[4].vendredi1.input1}h${menu_hours[4].vendredi1.input2} à ${menu_hours[4].vendredi1.input3}:${menu_hours[4].vendredi1.input4} - ${menu_hours[4].vendredi1.input5}:${menu_hours[4].vendredi1.input6} à ${menu_hours[4].vendredi1.input7}:${menu_hours[4].vendredi1.input8}`
                  : "Fermé "
                : "",
            samedi:
              menu_picto.picto1 === 1
                ? menu_hours[5].samedi1.select === true
                  ? menu_picto.picto2 === 1
                    ? `${menu_hours[5].samedi1.input1}h${menu_hours[5].samedi1.input2} à ${menu_hours[5].samedi1.input3}:${menu_hours[5].samedi1.input4}`
                    : `${menu_hours[5].samedi1.input1}h${menu_hours[5].samedi1.input2} à ${menu_hours[5].samedi1.input3}:${menu_hours[5].samedi1.input4} - ${menu_hours[5].samedi1.input5}:${menu_hours[5].samedi1.input6} à ${menu_hours[5].samedi1.input7}:${menu_hours[5].samedi1.input8}`
                  : "Fermé "
                : "",
            dimanche:
              menu_picto.picto1 === 1
                ? menu_hours[6].dimanche1.select === true
                  ? menu_picto.picto2 === 1
                    ? `${menu_hours[6].dimanche1.input1}h${menu_hours[6].dimanche1.input2} à ${menu_hours[6].dimanche1.input3}:${menu_hours[6].dimanche1.input4}`
                    : `${menu_hours[6].dimanche1.input1}h${menu_hours[6].dimanche1.input2} à ${menu_hours[6].dimanche1.input3}:${menu_hours[6].dimanche1.input4} - ${menu_hours[6].dimanche1.input5}:${menu_hours[6].dimanche1.input6} à ${menu_hours[6].dimanche1.input7}:${menu_hours[6].dimanche1.input8}`
                  : "Fermé "
                : "",

            fermeture: menu_picto.picto3 === 2 ? "OUI" : "NON",

            email_fermeture:
              menu_picto.picto3 === 2
                ? menu_email[2] === undefined || menu_email[2].email === ""
                  ? menu_email[1] === undefined || menu_email[1].email === ""
                    ? `${menu_email[0].email}`
                    : `${menu_email[0].email}, ${menu_email[1].email}`
                  : `${menu_email[0].email}, ${menu_email[1].email}, ${menu_email[2].email}`
                : "",

            iban: mandat_infos.iban,
            nom_societe: mandat_infos.societe,
            pays: mandat_infos.pays,
            ville: mandat_infos.ville,
            adresse: mandat_infos.adresse,
            postal: mandat_infos.postal,

            emailType: 2,
            config_choix: 2,
          });
          sessionStorage.setItem("config_send", true);
        } else {
          return false;
        }

        break;

      case "3":
        if (
          menu_redac.accueil !== "" &&
          menu_redac.accueil !== null &&
          menu_picto.picto1 !== "" &&
          menu_picto.picto1 !== null &&
          menu_picto.picto2 !== "" &&
          menu_picto.picto2 !== null &&
          menu_picto.picto3 !== "" &&
          menu_picto.picto3 !== null
        ) {
          sendEmail({
            email: client.email,

            nom_prenom: `${mandat_infos.nom} ${mandat_infos.prenom}`,
            numero: num_buy,
            societe: nom_societe,
            email_contact: email_contact,
            relation_unique: num_renvoi,

            message_accueil: menu_redac.accueil,
            message_attente: menu_redac.attente,
            message_fermeture: menu_redac.fermeture,

            lundi:
              menu_picto.picto1 === 1
                ? menu_hours[0].lundi1.select === true
                  ? menu_picto.picto2 === 1
                    ? `${menu_hours[0].lundi1.input1}h${menu_hours[0].lundi1.input2} à ${menu_hours[0].lundi1.input3}:${menu_hours[0].lundi1.input4}`
                    : `${menu_hours[0].lundi1.input1}h${menu_hours[0].lundi1.input2} à ${menu_hours[0].lundi1.input3}:${menu_hours[0].lundi1.input4} - ${menu_hours[0].lundi1.input5}:${menu_hours[0].lundi1.input6} à ${menu_hours[0].lundi1.input7}:${menu_hours[0].lundi1.input8}`
                  : "Fermé "
                : "",
            mardi:
              menu_picto.picto1 === 1
                ? menu_hours[1].mardi1.select === true
                  ? menu_picto.picto2 === 1
                    ? `${menu_hours[1].mardi1.input1}h${menu_hours[1].mardi1.input2} à ${menu_hours[1].mardi1.input3}:${menu_hours[1].mardi1.input4}`
                    : `${menu_hours[1].mardi1.input1}h${menu_hours[1].mardi1.input2} à ${menu_hours[1].mardi1.input3}:${menu_hours[1].mardi1.input4} - ${menu_hours[1].mardi1.input5}:${menu_hours[1].mardi1.input6} à ${menu_hours[1].mardi1.input7}:${menu_hours[1].mardi1.input8}`
                  : "Fermé "
                : "",
            mercredi:
              menu_picto.picto1 === 1
                ? menu_hours[2].mercredi1.select === true
                  ? menu_picto.picto2 === 1
                    ? `${menu_hours[2].mercredi1.input1}h${menu_hours[2].mercredi1.input2} à ${menu_hours[2].mercredi1.input3}:${menu_hours[2].mercredi1.input4}`
                    : `${menu_hours[2].mercredi1.input1}h${menu_hours[2].mercredi1.input2} à ${menu_hours[2].mercredi1.input3}:${menu_hours[2].mercredi1.input4} - ${menu_hours[2].mercredi1.input5}:${menu_hours[2].mercredi1.input6} à ${menu_hours[2].mercredi1.input7}:${menu_hours[2].mercredi1.input8}`
                  : "Fermé "
                : "",
            jeudi:
              menu_picto.picto1 === 1
                ? menu_hours[3].jeudi1.select === true
                  ? menu_picto.picto2 === 1
                    ? `${menu_hours[3].jeudi1.input1}h${menu_hours[3].jeudi1.input2} à ${menu_hours[3].jeudi1.input3}:${menu_hours[3].jeudi1.input4}`
                    : `${menu_hours[3].jeudi1.input1}h${menu_hours[3].jeudi1.input2} à ${menu_hours[3].jeudi1.input3}:${menu_hours[3].jeudi1.input4} - ${menu_hours[3].jeudi1.input5}:${menu_hours[3].jeudi1.input6} à ${menu_hours[3].jeudi1.input7}:${menu_hours[3].jeudi1.input8}`
                  : "Fermé "
                : "",
            vendredi:
              menu_picto.picto1 === 1
                ? menu_hours[4].vendredi1.select === true
                  ? menu_picto.picto2 === 1
                    ? `${menu_hours[4].vendredi1.input1}h${menu_hours[4].vendredi1.input2} à ${menu_hours[4].vendredi1.input3}:${menu_hours[4].vendredi1.input4}`
                    : `${menu_hours[4].vendredi1.input1}h${menu_hours[4].vendredi1.input2} à ${menu_hours[4].vendredi1.input3}:${menu_hours[4].vendredi1.input4} - ${menu_hours[4].vendredi1.input5}:${menu_hours[4].vendredi1.input6} à ${menu_hours[4].vendredi1.input7}:${menu_hours[4].vendredi1.input8}`
                  : "Fermé "
                : "",
            samedi:
              menu_picto.picto1 === 1
                ? menu_hours[5].samedi1.select === true
                  ? menu_picto.picto2 === 1
                    ? `${menu_hours[5].samedi1.input1}h${menu_hours[5].samedi1.input2} à ${menu_hours[5].samedi1.input3}:${menu_hours[5].samedi1.input4}`
                    : `${menu_hours[5].samedi1.input1}h${menu_hours[5].samedi1.input2} à ${menu_hours[5].samedi1.input3}:${menu_hours[5].samedi1.input4} - ${menu_hours[5].samedi1.input5}:${menu_hours[5].samedi1.input6} à ${menu_hours[5].samedi1.input7}:${menu_hours[5].samedi1.input8}`
                  : "Fermé "
                : "",
            dimanche:
              menu_picto.picto1 === 1
                ? menu_hours[6].dimanche1.select === true
                  ? menu_picto.picto2 === 1
                    ? `${menu_hours[6].dimanche1.input1}h${menu_hours[6].dimanche1.input2} à ${menu_hours[6].dimanche1.input3}:${menu_hours[6].dimanche1.input4}`
                    : `${menu_hours[6].dimanche1.input1}h${menu_hours[6].dimanche1.input2} à ${menu_hours[6].dimanche1.input3}:${menu_hours[6].dimanche1.input4} - ${menu_hours[6].dimanche1.input5}:${menu_hours[6].dimanche1.input6} à ${menu_hours[6].dimanche1.input7}:${menu_hours[6].dimanche1.input8}`
                  : "Fermé "
                : "",

            fermeture: menu_picto.picto3 === 2 ? "OUI" : "NON",

            email_fermeture:
              menu_picto.picto3 === 2
                ? menu_email[2] === undefined || menu_email[2].email === ""
                  ? menu_email[1] === undefined || menu_email[1].email === ""
                    ? `${menu_email[0].email}`
                    : `${menu_email[0].email}, ${menu_email[1].email}`
                  : `${menu_email[0].email}, ${menu_email[1].email}, ${menu_email[2].email}`
                : "",

            number_menu: config_choix_number,

            choix1_intitule: config_choix_1.intitule,
            choix1_numero1: config_choix_1.renvoi,
            choix1_numero2:
              config_numero_1 !== null && config_numero_1 !== ""
                ? config_numero_1[0] !== undefined
                  ? config_numero_1[0].num
                  : ""
                : "",
            choix1_numero3:
              config_numero_1 !== null && config_numero_1 !== ""
                ? config_numero_1[1] !== undefined
                  ? config_numero_1[1].num
                  : ""
                : "",
            choix1_numero4:
              config_numero_1 !== null && config_numero_1 !== ""
                ? config_numero_1[2] !== undefined
                  ? config_numero_1[2].num
                  : ""
                : "",
            choix1_numero5:
              config_numero_1 !== null && config_numero_1 !== ""
                ? config_numero_1[3] !== undefined
                  ? config_numero_1[3].num
                  : ""
                : "",

            choix2_intitule: config_choix_2.intitule,
            choix2_numero1: config_choix_2.renvoi,
            choix2_numero2:
              config_numero_2 !== null && config_numero_2 !== ""
                ? config_numero_2[0] !== undefined
                  ? config_numero_2[0].num
                  : ""
                : "",
            choix2_numero3:
              config_numero_2 !== null && config_numero_2 !== ""
                ? config_numero_2[1] !== undefined
                  ? config_numero_2[1].num
                  : ""
                : "",
            choix2_numero4:
              config_numero_2 !== null && config_numero_2 !== ""
                ? config_numero_2[2] !== undefined
                  ? config_numero_2[2].num
                  : ""
                : "",
            choix2_numero5:
              config_numero_2 !== null && config_numero_2 !== ""
                ? config_numero_2[3] !== undefined
                  ? config_numero_2[3].num
                  : ""
                : "",

            choix3_intitule:
              config_choix_number === 3 ? config_choix_3.intitule || "" : "",
            choix3_numero1:
              config_choix_number === 3 ? config_choix_3.renvoi || "" : "",
            choix3_numero2:
              config_choix_number === 3
                ? config_numero_3 !== null && config_numero_3 !== ""
                  ? config_numero_3[0] !== undefined
                    ? config_numero_3[0].num
                    : ""
                  : ""
                : "",
            choix3_numero3:
              config_choix_number === 3
                ? config_numero_3 !== null && config_numero_3 !== ""
                  ? config_numero_3[1] !== undefined
                    ? config_numero_3[1].num
                    : ""
                  : ""
                : "",
            choix3_numero4:
              config_choix_number === 3
                ? config_numero_3 !== null && config_numero_3 !== ""
                  ? config_numero_3[2] !== undefined
                    ? config_numero_3[2].num
                    : ""
                  : ""
                : "",
            choix3_numero5:
              config_choix_number === 3
                ? config_numero_3 !== null && config_numero_3 !== ""
                  ? config_numero_3[3] !== undefined
                    ? config_numero_3[3].num
                    : ""
                  : ""
                : "",

            iban: mandat_infos.iban,
            nom_societe: mandat_infos.societe,
            pays: mandat_infos.pays,
            ville: mandat_infos.ville,
            adresse: mandat_infos.adresse,
            postal: mandat_infos.postal,

            emailType: 2,
            config_choix: 3,
          });
          sessionStorage.setItem("config_send", true);
        } else {
          return false;
        }

        break;

      default:
        return false;
    }

    return true;
  } catch (err) {
    throw err;
  }
};

export default sendEmailFinish;
