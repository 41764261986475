import { useEffect, useState } from 'react';

import '../../../assets/css/Config/Step/Menu.css';

import check from '../../../assets/images/picto/victo_check.svg';

function MenuHours() {

    const[allSelect, setAllSelect] = useState(false)

    const[copy, setCopy] = useState({select: true, input1: "8", input2: "30", input3: "12", input4: "30", input5: "13", input6: "30", input7: "17", input8: "30"})

    const[lundi1, setLundi1] = useState({select: true, input1: "8", input2: "30", input3: "12", input4: "30", input5: "13", input6: "30", input7: "17", input8: "30"})
    const[mardi1, setMardi1] = useState({select: true, input1: 8, input2: 30, input3: 12, input4: 30, input5: 13, input6: 30, input7: 17, input8: 30})
    const[mercredi1, setMercredi1] = useState({select: true, input1: 8, input2: 30, input3: 12, input4: 30, input5: 13, input6: 30, input7: 17, input8: 30})
    const[jeudi1, setJeudi1] = useState({select: true, input1: 8, input2: 30, input3: 12, input4: 30, input5: 13, input6: 30, input7: 17, input8: 30})
    const[vendredi1, setVendredi1] = useState({select: true, input1: 8, input2: 30, input3: 12, input4: 30, input5: 13, input6: 30, input7: 17, input8: 30})
    const[samedi1, setSamedi1] = useState({select: false, input1: 8, input2: 30, input3: 12, input4: 30, input5: 13, input6: 30, input7: 17, input8: 30})
    const[dimanche1, setDimanche1] = useState({select: false, input1: 8, input2: 30, input3: 12, input4: 30, input5: 13, input6: 30, input7: 17, input8: 30})

    const handleCopy = (dayState, day) => {
        document.getElementById('menu-popup').innerHTML = `<p>Les horaires du jour : "${day}" ont bien été copiés !</p>`;
        document.getElementById('menu-popup').style.display = 'block';
        setTimeout(function() {
            document.getElementById('menu-popup').innerHTML = "";
            document.getElementById('menu-popup').style.display = 'none';
        },3000);
        setCopy(dayState)
        sessionStorage.setItem('copyHours', JSON.stringify(dayState))
    }

    const handlePaste = (dayState, day) => {
        document.getElementById('menu-popup').innerHTML = `<p>Les horaires du jour : "${day}" ont bien été modifiés !</p>`;
        document.getElementById('menu-popup').style.display = 'block';
        setTimeout(function() {
            document.getElementById('menu-popup').innerHTML = "";
            document.getElementById('menu-popup').style.display = 'none';
        },3000);
        let localHours = JSON.parse(sessionStorage.getItem('copyHours'))
        if(localHours === null) {
            dayState(copy)
        } else {
            dayState(localHours)
        }
    }

    const handleApply = (dayState, day) => {
        let count = 0
        document.getElementById('menu-popup').innerHTML = `<p>Les horaires du jour : "${day}" ont bien été appliqués à tous les jours sélectionnés !</p>`;
        document.getElementById('menu-popup').style.display = 'block';
        setTimeout(function() {
            document.getElementById('menu-popup').innerHTML = "";
            document.getElementById('menu-popup').style.display = 'none';
        },3000);
        if(lundi1.select === true) {
            setLundi1(dayState)
            count = count + 1
        }
        if(mardi1.select === true) {
            setMardi1(dayState)
            count = count + 1
        }
        if(mercredi1.select === true) {
            setMercredi1(dayState)
            count = count + 1
        }
        if(jeudi1.select === true) {
            setJeudi1(dayState)
            count = count + 1
        }
        if(vendredi1.select === true) {
            setVendredi1(dayState)
            count = count + 1
        }
        if(samedi1.select === true) {
            setSamedi1(dayState)
            count = count + 1
        }
        if(dimanche1.select === true) {
            setDimanche1(dayState)
            count = count + 1
        }

        if(count === 7) {
            setAllSelect(true)
        }
    }

    const handleAllSelect = (evt) => {
        if(evt === 'set') {
            setLundi1({ ...lundi1, select: true})
            setMardi1({ ...mardi1, select: true })
            setMercredi1({ ...mercredi1, select: true })
            setJeudi1({ ...jeudi1, select: true })
            setVendredi1({ ...vendredi1, select: true })
            setSamedi1({ ...samedi1, select: true })
            setDimanche1({ ...dimanche1, select: true })

            setAllSelect(true)
        } else if(evt === 'unset') {
            setLundi1({ ...lundi1, select: false })
            setMardi1({ ...mardi1, select: false })
            setMercredi1({ ...mercredi1, select: false })
            setJeudi1({ ...jeudi1, select: false })
            setVendredi1({ ...vendredi1, select: false })
            setSamedi1({ ...samedi1, select: false })
            setDimanche1({ ...dimanche1, select: false })

            setAllSelect(false)
        }
    }

    const handleSelect = (day, dayState, evt) => {
        if(evt === 'set') {
            dayState({ ...day, select: true})
        } else if(evt === 'unset') {
            dayState({ ...day, select: false})

        }
    }

    useEffect(() => {
        let stringify_hours = JSON.stringify(
            [
                {lundi1},
                {mardi1},
                {mercredi1},
                {jeudi1},
                {vendredi1},
                {samedi1},
                {dimanche1}
            ]
        )

        sessionStorage.setItem('menu_hours', stringify_hours)
    })

    return (
        <div className="menu-hours">
            <p className="menu-hours-title">Saisissez vos jours d'ouverture*</p>
            <div className="menu-hours-all">
                <div className="menu-allselect menu-flexed menu-cursor" onClick={() => { allSelect ? handleAllSelect('unset') : handleAllSelect('set')}}>
                    { allSelect ?
                        <img src={check} alt='check-colortelecom'/>
                    :
                        <div className="menu-checkbox"></div>
                    }
                    <p>Tout séléctionner</p>
                </div>

                <div className="menu-day-mobile">
                    <div className="menu-day menu-flexed menu-cursor" onClick={() => { lundi1.select ? handleSelect(lundi1, setLundi1, 'unset') : handleSelect(lundi1, setLundi1, 'set')}}>
                        { lundi1.select ?
                            <img src={check} alt='check-colortelecom'/>
                        :
                            <div className="menu-checkbox"></div>
                        }
                            <p>Lundi</p>
                    </div>
                    <div className="menu-day menu-flexed menu-cursor" onClick={() => { mardi1.select ? handleSelect(mardi1, setMardi1, 'unset') : handleSelect(mardi1, setMardi1, 'set')}}>
                        { mardi1.select ?
                            <img src={check} alt='check-colortelecom'/>
                        :
                            <div className="menu-checkbox"></div>
                        }
                            <p>Mardi</p>
                    </div>
                    <div className="menu-day menu-flexed menu-cursor" onClick={() => { mercredi1.select ? handleSelect(mercredi1, setMercredi1, 'unset') : handleSelect(mercredi1, setMercredi1, 'set')}}>
                        { mercredi1.select ?
                            <img src={check} alt='check-colortelecom'/>
                        :
                            <div className="menu-checkbox"></div>
                        }
                            <p>Mercredi</p>
                    </div>
                    <div className="menu-day menu-flexed menu-cursor" onClick={() => { jeudi1.select ? handleSelect(jeudi1, setJeudi1, 'unset') : handleSelect(jeudi1, setJeudi1, 'set')}}>
                        { jeudi1.select ?
                            <img src={check} alt='check-colortelecom'/>
                        :
                            <div className="menu-checkbox"></div>
                        }
                            <p>Jeudi</p>
                    </div>
                    <div className="menu-day menu-flexed menu-cursor" onClick={() => { vendredi1.select ? handleSelect(vendredi1, setVendredi1, 'unset') : handleSelect(vendredi1, setVendredi1, 'set')}}>
                        { vendredi1.select ?
                            <img src={check} alt='check-colortelecom'/>
                        :
                            <div className="menu-checkbox"></div>
                        }
                            <p>Vendredi</p>
                    </div>
                    <div className="menu-day menu-flexed menu-cursor" onClick={() => { samedi1.select ? handleSelect(samedi1, setSamedi1, 'unset') : handleSelect(samedi1, setSamedi1, 'set')}}>
                        { samedi1.select ?
                            <img src={check} alt='check-colortelecom'/>
                        :
                            <div className="menu-checkbox"></div>
                        }
                            <p>Samedi</p>
                    </div>
                    <div className="menu-day menu-flexed menu-cursor" onClick={() => { dimanche1.select ? handleSelect(dimanche1, setDimanche1, 'unset') : handleSelect(dimanche1, setDimanche1, 'set')}}>
                        { dimanche1.select ?
                            <img src={check} alt='check-colortelecom'/>
                        :
                            <div className="menu-checkbox"></div>
                        }
                            <p>Dimanche</p>
                    </div>
                </div>

                <div className="menu-hours-header">
                    <p className="menu-day-mobile menu-day-title">Lundi</p>
                    <p className="menu-hours-pause">Pause</p>
                    <div className="menu-hours-body menu-flexed">
                        <div className="menu-day menu-flexed menu-cursor menu-display" onClick={() => { lundi1.select ? handleSelect(lundi1, setLundi1, 'unset') : handleSelect(lundi1, setLundi1, 'set')}}>
                        { lundi1.select ?
                            <img src={check} alt='check-colortelecom'/>
                        :
                            <div className="menu-checkbox"></div>
                        }
                            <p>Lundi</p>
                        </div>
                        <div className="menu-flexed menu-hours-body-mobile">

                            <div className="menu-flexed">
                                <div className="menu-flexed">
                                    <input disabled={lundi1.select ? null : "disabled" } type="text" value={lundi1.input1} maxLength="2" onChange={(val) => setLundi1({ ...lundi1, input1: val.target.value})}/>
                                    <p className="menu-hours-p">:</p>
                                    <input disabled={lundi1.select ? null : "disabled" } type="text" value={lundi1.input2} maxLength="2" onChange={(val) => setLundi1({ ...lundi1, input2: val.target.value})}/>
                                </div>

                                <p className="menu-hours-p">à</p>

                                <div className="menu-flexed">
                                    <input style={lundi1.select ? {border: '1px solid #016589'} : null} disabled={lundi1.select ? null : "disabled" } type="text" value={lundi1.input3} maxLength="2" onChange={(val) => setLundi1({ ...lundi1, input3: val.target.value})}/>
                                    <p className="menu-hours-p">:</p>
                                    <input style={lundi1.select ? {border: '1px solid #016589'} : null} disabled={lundi1.select ? null : "disabled" } type="text" value={lundi1.input4} maxLength="2" onChange={(val) => setLundi1({ ...lundi1, input4: val.target.value})}/>
                                </div>

                                <p className="menu-hours-p">-</p>

                                <div className="menu-flexed">
                                    <input style={lundi1.select ? {border: '1px solid #016589'} : null} disabled={lundi1.select ? null : "disabled" } type="text" value={lundi1.input5} maxLength="2" onChange={(val) => setLundi1({ ...lundi1, input5: val.target.value})}/>
                                    <p className="menu-hours-p">:</p>
                                    <input style={lundi1.select ? {border: '1px solid #016589'} : null} disabled={lundi1.select ? null : "disabled" } type="text" value={lundi1.input6} maxLength="2" onChange={(val) => setLundi1({ ...lundi1, input6: val.target.value})}/>
                                </div>

                                <p className="menu-hours-p">à</p>

                                <div className="menu-flexed">
                                    <input disabled={lundi1.select ? null : "disabled" } type="text" value={lundi1.input7} maxLength="2" onChange={(val) => setLundi1({ ...lundi1, input7: val.target.value})}/>
                                    <p className="menu-hours-p">:</p>
                                    <input disabled={lundi1.select ? null : "disabled" } type="text" value={lundi1.input8} maxLength="2" onChange={(val) => setLundi1({ ...lundi1, input8: val.target.value})}/>
                                </div>
                            </div>

                            <div className="menu-hours-allbutton menu-flexed">
                                <p className={ lundi1.select ? "menu-hours-button menu-button-select" : "menu-hours-button menu-button-noselect" } onClick={() => handleCopy(lundi1, 'lundi')}>Copier</p>
                                <p className={ lundi1.select ? "menu-hours-button menu-button-select" : "menu-hours-button menu-button-noselect" } onClick={() => handlePaste(setLundi1, 'lundi')}>Coller</p>
                                <p className={ lundi1.select ? "menu-hours-button menu-button-select" : "menu-hours-button menu-button-noselect" } onClick={() => handleApply(lundi1, 'lundi')}>
                                    Appliquer à tous
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="menu-hours-header">
                    <p className="menu-day-mobile menu-day-title">Mardi</p>
                    <p className="menu-hours-pause">Pause</p>
                    <div className="menu-hours-body menu-flexed">
                        <div className="menu-day menu-flexed menu-cursor menu-display" onClick={() => { mardi1.select ? handleSelect(mardi1, setMardi1, 'unset') : handleSelect(mardi1, setMardi1, 'set')}}>
                        { mardi1.select ?
                            <img src={check} alt='check-colortelecom'/>
                        :
                            <div className="menu-checkbox"></div>
                        }
                            <p>Mardi</p>
                        </div>
                        <div className="menu-flexed menu-hours-body-mobile">

                            <div className="menu-flexed">
                                <div className="menu-flexed">
                                    <input disabled={mardi1.select ? null : "disabled" } type="text" value={mardi1.input1} maxLength="2" onChange={(val) => setMardi1({ ...mardi1, input1: val.target.value})}/>
                                    <p className="menu-hours-p">:</p>
                                    <input disabled={mardi1.select ? null : "disabled" } type="text" value={mardi1.input2} maxLength="2" onChange={(val) => setMardi1({ ...mardi1, input2: val.target.value})}/>
                                </div>

                                <p className="menu-hours-p">à</p>

                                <div className="menu-flexed">
                                    <input style={mardi1.select ? {border: '1px solid #016589'} : null} disabled={mardi1.select ? null : "disabled" } type="text" value={mardi1.input3} maxLength="2" onChange={(val) => setMardi1({ ...mardi1, input3: val.target.value})}/>
                                    <p className="menu-hours-p">:</p>
                                    <input style={mardi1.select ? {border: '1px solid #016589'} : null} disabled={mardi1.select ? null : "disabled" } type="text" value={mardi1.input4} maxLength="2" onChange={(val) => setMardi1({ ...mardi1, input4: val.target.value})}/>
                                </div>

                                <p className="menu-hours-p">-</p>

                                <div className="menu-flexed">
                                    <input style={mardi1.select ? {border: '1px solid #016589'} : null} disabled={mardi1.select ? null : "disabled" } type="text" value={mardi1.input5} maxLength="2" onChange={(val) => setMardi1({ ...mardi1, input5: val.target.value})}/>
                                    <p className="menu-hours-p">:</p>
                                    <input style={mardi1.select ? {border: '1px solid #016589'} : null} disabled={mardi1.select ? null : "disabled" } type="text" value={mardi1.input6} maxLength="2" onChange={(val) => setMardi1({ ...mardi1, input6: val.target.value})}/>
                                </div>

                                <p className="menu-hours-p">à</p>

                                <div className="menu-flexed">
                                    <input disabled={mardi1.select ? null : "disabled" } type="text" value={mardi1.input7} maxLength="2" onChange={(val) => setMardi1({ ...mardi1, input7: val.target.value})}/>
                                    <p className="menu-hours-p">:</p>
                                    <input disabled={mardi1.select ? null : "disabled" } type="text" value={mardi1.input8} maxLength="2" onChange={(val) => setMardi1({ ...mardi1, input8: val.target.value})}/>
                                </div>
                            </div>

                            <div className="menu-hours-allbutton menu-flexed">
                                <p className={ mardi1.select ? "menu-hours-button menu-button-select" : "menu-hours-button menu-button-noselect" } onClick={() => handleCopy(mardi1, 'mardi')}>Copier</p>
                                <p className={ mardi1.select ? "menu-hours-button menu-button-select" : "menu-hours-button menu-button-noselect" } onClick={() => handlePaste(setMardi1, 'mardi')}>Coller</p>
                                <p className={ mardi1.select ? "menu-hours-button menu-button-select" : "menu-hours-button menu-button-noselect" } onClick={() => handleApply(mardi1, 'mardi')}>
                                    Appliquer à tous
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="menu-hours-header">
                    <p className="menu-day-mobile menu-day-title">Mercredi</p>
                    <p className="menu-hours-pause">Pause</p>
                    <div className="menu-hours-body menu-flexed">
                        <div className="menu-day menu-flexed menu-cursor menu-display" onClick={() => { mercredi1.select ? handleSelect(mercredi1, setMercredi1, 'unset') : handleSelect(mercredi1, setMercredi1, 'set')}}>
                        { mercredi1.select ?
                            <img src={check} alt='check-colortelecom'/>
                        :
                            <div className="menu-checkbox"></div>
                        }
                            <p>Mercredi</p>
                        </div>
                        <div className="menu-flexed menu-hours-body-mobile">

                            <div className="menu-flexed">
                                <div className="menu-flexed">
                                    <input disabled={mercredi1.select ? null : "disabled" } type="text" value={mercredi1.input1} maxLength="2" onChange={(val) => setMercredi1({ ...mercredi1, input1: val.target.value})}/>
                                    <p className="menu-hours-p">:</p>
                                    <input disabled={mercredi1.select ? null : "disabled" } type="text" value={mercredi1.input2} maxLength="2" onChange={(val) => setMercredi1({ ...mercredi1, input2: val.target.value})}/>
                                </div>

                                <p className="menu-hours-p">à</p>

                                <div className="menu-flexed">
                                    <input style={mercredi1.select ? {border: '1px solid #016589'} : null} disabled={mercredi1.select ? null : "disabled" } type="text" value={mercredi1.input3} maxLength="2" onChange={(val) => setMercredi1({ ...mercredi1, input3: val.target.value})}/>
                                    <p className="menu-hours-p">:</p>
                                    <input style={mercredi1.select ? {border: '1px solid #016589'} : null} disabled={mercredi1.select ? null : "disabled" } type="text" value={mercredi1.input4} maxLength="2" onChange={(val) => setMercredi1({ ...mercredi1, input4: val.target.value})}/>
                                </div>

                                <p className="menu-hours-p">-</p>

                                <div className="menu-flexed">
                                    <input style={mercredi1.select ? {border: '1px solid #016589'} : null} disabled={mercredi1.select ? null : "disabled" } type="text" value={mercredi1.input5} maxLength="2" onChange={(val) => setMercredi1({ ...mercredi1, input5: val.target.value})}/>
                                    <p className="menu-hours-p">:</p>
                                    <input style={mercredi1.select ? {border: '1px solid #016589'} : null} disabled={mercredi1.select ? null : "disabled" } type="text" value={mercredi1.input6} maxLength="2" onChange={(val) => setMercredi1({ ...mercredi1, input6: val.target.value})}/>
                                </div>

                                <p className="menu-hours-p">à</p>

                                <div className="menu-flexed">
                                    <input disabled={mercredi1.select ? null : "disabled" } type="text" value={mercredi1.input7} maxLength="2" onChange={(val) => setMercredi1({ ...mercredi1, input7: val.target.value})}/>
                                    <p className="menu-hours-p">:</p>
                                    <input disabled={mercredi1.select ? null : "disabled" } type="text" value={mercredi1.input8} maxLength="2" onChange={(val) => setMercredi1({ ...mercredi1, input8: val.target.value})}/>
                                </div>
                            </div>

                            <div className="menu-hours-allbutton menu-flexed">
                                <p className={ mercredi1.select ? "menu-hours-button menu-button-select" : "menu-hours-button menu-button-noselect" } onClick={() => handleCopy(mercredi1, 'mercredi')}>Copier</p>
                                <p className={ mercredi1.select ? "menu-hours-button menu-button-select" : "menu-hours-button menu-button-noselect" } onClick={() => handlePaste(setMercredi1, 'mercredi')}>Coller</p>
                                <p className={ mercredi1.select ? "menu-hours-button menu-button-select" : "menu-hours-button menu-button-noselect" } onClick={() => handleApply(mercredi1, 'mercredi')}>
                                    Appliquer à tous
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="menu-hours-header">
                    <p className="menu-day-mobile menu-day-title">Jeudi</p>
                    <p className="menu-hours-pause">Pause</p>
                    <div className="menu-hours-body menu-flexed">
                        <div className="menu-day menu-flexed menu-cursor menu-display" onClick={() => { jeudi1.select ? handleSelect(jeudi1, setJeudi1, 'unset') : handleSelect(jeudi1, setJeudi1, 'set')}}>
                        { jeudi1.select ?
                            <img src={check} alt='check-colortelecom'/>
                        :
                            <div className="menu-checkbox"></div>
                        }
                            <p>Jeudi</p>
                        </div>
                        <div className="menu-flexed menu-hours-body-mobile">

                            <div className="menu-flexed">
                                <div className="menu-flexed">
                                    <input disabled={jeudi1.select ? null : "disabled" } type="text" value={jeudi1.input1} maxLength="2" onChange={(val) => setJeudi1({ ...jeudi1, input1: val.target.value})}/>
                                    <p className="menu-hours-p">:</p>
                                    <input disabled={jeudi1.select ? null : "disabled" } type="text" value={jeudi1.input2} maxLength="2" onChange={(val) => setJeudi1({ ...jeudi1, input2: val.target.value})}/>
                                </div>

                                <p className="menu-hours-p">à</p>

                                <div className="menu-flexed">
                                    <input style={jeudi1.select ? {border: '1px solid #016589'} : null} disabled={jeudi1.select ? null : "disabled" } type="text" value={jeudi1.input3} maxLength="2" onChange={(val) => setJeudi1({ ...jeudi1, input3: val.target.value})}/>
                                    <p className="menu-hours-p">:</p>
                                    <input style={jeudi1.select ? {border: '1px solid #016589'} : null} disabled={jeudi1.select ? null : "disabled" } type="text" value={jeudi1.input4} maxLength="2" onChange={(val) => setJeudi1({ ...jeudi1, input4: val.target.value})}/>
                                </div>

                                <p className="menu-hours-p">-</p>

                                <div className="menu-flexed">
                                    <input style={jeudi1.select ? {border: '1px solid #016589'} : null} disabled={jeudi1.select ? null : "disabled" } type="text" value={jeudi1.input5} maxLength="2" onChange={(val) => setJeudi1({ ...jeudi1, input5: val.target.value})}/>
                                    <p className="menu-hours-p">:</p>
                                    <input style={jeudi1.select ? {border: '1px solid #016589'} : null} disabled={jeudi1.select ? null : "disabled" } type="text" value={jeudi1.input6} maxLength="2" onChange={(val) => setJeudi1({ ...jeudi1, input6: val.target.value})}/>
                                </div>

                                <p className="menu-hours-p">à</p>

                                <div className="menu-flexed">
                                    <input disabled={jeudi1.select ? null : "disabled" } type="text" value={jeudi1.input7} maxLength="2" onChange={(val) => setJeudi1({ ...jeudi1, input7: val.target.value})}/>
                                    <p className="menu-hours-p">:</p>
                                    <input disabled={jeudi1.select ? null : "disabled" } type="text" value={jeudi1.input8} maxLength="2" onChange={(val) => setJeudi1({ ...jeudi1, input8: val.target.value})}/>
                                </div>
                            </div>

                            <div className="menu-hours-allbutton menu-flexed">
                                <p className={ jeudi1.select ? "menu-hours-button menu-button-select" : "menu-hours-button menu-button-noselect" } onClick={() => handleCopy(jeudi1, 'jeudi')}>Copier</p>
                                <p className={ jeudi1.select ? "menu-hours-button menu-button-select" : "menu-hours-button menu-button-noselect" } onClick={() => handlePaste(setJeudi1, 'jeudi')}>Coller</p>
                                <p className={ jeudi1.select ? "menu-hours-button menu-button-select" : "menu-hours-button menu-button-noselect" } onClick={() => handleApply(jeudi1, 'jeudi')}>
                                    Appliquer à tous
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="menu-hours-header">
                    <p className="menu-day-mobile menu-day-title">Vendredi</p>
                    <p className="menu-hours-pause">Pause</p>
                    <div className="menu-hours-body menu-flexed">
                        <div className="menu-day menu-flexed menu-cursor menu-display" onClick={() => { vendredi1.select ? handleSelect(vendredi1, setVendredi1, 'unset') : handleSelect(vendredi1, setVendredi1, 'set')}}>
                        { vendredi1.select ?
                            <img src={check} alt='check-colortelecom'/>
                        :
                            <div className="menu-checkbox"></div>
                        }
                            <p>Vendredi</p>
                        </div>
                        <div className="menu-flexed menu-hours-body-mobile">

                            <div className="menu-flexed">
                                <div className="menu-flexed">
                                    <input disabled={vendredi1.select ? null : "disabled" } type="text" value={vendredi1.input1} maxLength="2" onChange={(val) => setVendredi1({ ...vendredi1, input1: val.target.value})}/>
                                    <p className="menu-hours-p">:</p>
                                    <input disabled={vendredi1.select ? null : "disabled" } type="text" value={vendredi1.input2} maxLength="2" onChange={(val) => setVendredi1({ ...vendredi1, input2: val.target.value})}/>
                                </div>

                                <p className="menu-hours-p">à</p>

                                <div className="menu-flexed">
                                    <input style={vendredi1.select ? {border: '1px solid #016589'} : null} disabled={vendredi1.select ? null : "disabled" } type="text" value={vendredi1.input3} maxLength="2" onChange={(val) => setVendredi1({ ...vendredi1, input3: val.target.value})}/>
                                    <p className="menu-hours-p">:</p>
                                    <input style={vendredi1.select ? {border: '1px solid #016589'} : null} disabled={vendredi1.select ? null : "disabled" } type="text" value={vendredi1.input4} maxLength="2" onChange={(val) => setVendredi1({ ...vendredi1, input4: val.target.value})}/>
                                </div>

                                <p className="menu-hours-p">-</p>

                                <div className="menu-flexed">
                                    <input style={vendredi1.select ? {border: '1px solid #016589'} : null} disabled={vendredi1.select ? null : "disabled" } type="text" value={vendredi1.input5} maxLength="2" onChange={(val) => setVendredi1({ ...vendredi1, input5: val.target.value})}/>
                                    <p className="menu-hours-p">:</p>
                                    <input style={vendredi1.select ? {border: '1px solid #016589'} : null} disabled={vendredi1.select ? null : "disabled" } type="text" value={vendredi1.input6} maxLength="2" onChange={(val) => setVendredi1({ ...vendredi1, input6: val.target.value})}/>
                                </div>

                                <p className="menu-hours-p">à</p>

                                <div className="menu-flexed">
                                    <input disabled={vendredi1.select ? null : "disabled" } type="text" value={vendredi1.input7} maxLength="2" onChange={(val) => setVendredi1({ ...vendredi1, input7: val.target.value})}/>
                                    <p className="menu-hours-p">:</p>
                                    <input disabled={vendredi1.select ? null : "disabled" } type="text" value={vendredi1.input8} maxLength="2" onChange={(val) => setVendredi1({ ...vendredi1, input8: val.target.value})}/>
                                </div>
                            </div>

                            <div className="menu-hours-allbutton menu-flexed">
                                <p className={ vendredi1.select ? "menu-hours-button menu-button-select" : "menu-hours-button menu-button-noselect" } onClick={() => handleCopy(vendredi1, 'vendredi')}>Copier</p>
                                <p className={ vendredi1.select ? "menu-hours-button menu-button-select" : "menu-hours-button menu-button-noselect" } onClick={() => handlePaste(setVendredi1, 'vendredi')}>Coller</p>
                                <p className={ vendredi1.select ? "menu-hours-button menu-button-select" : "menu-hours-button menu-button-noselect" } onClick={() => handleApply(vendredi1, 'vendredi')}>
                                    Appliquer à tous
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="menu-hours-header">
                    <p className="menu-day-mobile menu-day-title">Samedi</p>
                    <p className="menu-hours-pause">Pause</p>
                    <div className="menu-hours-body menu-flexed">
                        <div className="menu-day menu-flexed menu-cursor menu-display" onClick={() => { samedi1.select ? handleSelect(samedi1, setSamedi1, 'unset') : handleSelect(samedi1, setSamedi1, 'set')}}>
                        { samedi1.select ?
                            <img src={check} alt='check-colortelecom'/>
                        :
                            <div className="menu-checkbox"></div>
                        }
                            <p>Samedi</p>
                        </div>
                        <div className="menu-flexed menu-hours-body-mobile">

                            <div className="menu-flexed">
                                <div className="menu-flexed">
                                    <input disabled={samedi1.select ? null : "disabled" } type="text" value={samedi1.input1} maxLength="2" onChange={(val) => setSamedi1({ ...samedi1, input1: val.target.value})}/>
                                    <p className="menu-hours-p">:</p>
                                    <input disabled={samedi1.select ? null : "disabled" } type="text" value={samedi1.input2} maxLength="2" onChange={(val) => setSamedi1({ ...samedi1, input2: val.target.value})}/>
                                </div>

                                <p className="menu-hours-p">à</p>

                                <div className="menu-flexed">
                                    <input style={samedi1.select ? {border: '1px solid #016589'} : null} disabled={samedi1.select ? null : "disabled" } type="text" value={samedi1.input3} maxLength="2" onChange={(val) => setSamedi1({ ...samedi1, input3: val.target.value})}/>
                                    <p className="menu-hours-p">:</p>
                                    <input style={samedi1.select ? {border: '1px solid #016589'} : null} disabled={samedi1.select ? null : "disabled" } type="text" value={samedi1.input4} maxLength="2" onChange={(val) => setSamedi1({ ...samedi1, input4: val.target.value})}/>
                                </div>

                                <p className="menu-hours-p">-</p>

                                <div className="menu-flexed">
                                    <input style={samedi1.select ? {border: '1px solid #016589'} : null} disabled={samedi1.select ? null : "disabled" } type="text" value={samedi1.input5} maxLength="2" onChange={(val) => setSamedi1({ ...samedi1, input5: val.target.value})}/>
                                    <p className="menu-hours-p">:</p>
                                    <input style={samedi1.select ? {border: '1px solid #016589'} : null} disabled={samedi1.select ? null : "disabled" } type="text" value={samedi1.input6} maxLength="2" onChange={(val) => setSamedi1({ ...samedi1, input6: val.target.value})}/>
                                </div>

                                <p className="menu-hours-p">à</p>

                                <div className="menu-flexed">
                                    <input disabled={samedi1.select ? null : "disabled" } type="text" value={samedi1.input7} maxLength="2" onChange={(val) => setSamedi1({ ...samedi1, input7: val.target.value})}/>
                                    <p className="menu-hours-p">:</p>
                                    <input disabled={samedi1.select ? null : "disabled" } type="text" value={samedi1.input8} maxLength="2" onChange={(val) => setSamedi1({ ...samedi1, input8: val.target.value})}/>
                                </div>
                            </div>

                            <div className="menu-hours-allbutton menu-flexed">
                                <p className={ samedi1.select ? "menu-hours-button menu-button-select" : "menu-hours-button menu-button-noselect" } onClick={() => handleCopy(samedi1, 'samedi')}>Copier</p>
                                <p className={ samedi1.select ? "menu-hours-button menu-button-select" : "menu-hours-button menu-button-noselect" } onClick={() => handlePaste(setSamedi1, 'samedi')}>Coller</p>
                                <p className={ samedi1.select ? "menu-hours-button menu-button-select" : "menu-hours-button menu-button-noselect" } onClick={() => handleApply(samedi1, 'samedi')}>
                                    Appliquer à tous
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="menu-hours-header">
                    <p className="menu-day-mobile menu-day-title">Dimanche</p>
                    <p className="menu-hours-pause">Pause</p>
                    <div className="menu-hours-body menu-flexed">
                        <div className="menu-day menu-flexed menu-cursor menu-display" onClick={() => { dimanche1.select ? handleSelect(dimanche1, setDimanche1, 'unset') : handleSelect(dimanche1, setDimanche1, 'set')}}>
                        { dimanche1.select ?
                            <img src={check} alt='check-colortelecom'/>
                        :
                            <div className="menu-checkbox"></div>
                        }
                            <p>Dimanche</p>
                        </div>
                        <div className="menu-flexed menu-hours-body-mobile">

                            <div className="menu-flexed">
                                <div className="menu-flexed">
                                    <input disabled={dimanche1.select ? null : "disabled" } type="text" value={dimanche1.input1} maxLength="2" onChange={(val) => setDimanche1({ ...dimanche1, input1: val.target.value})}/>
                                    <p className="menu-hours-p">:</p>
                                    <input disabled={dimanche1.select ? null : "disabled" } type="text" value={dimanche1.input2} maxLength="2" onChange={(val) => setDimanche1({ ...dimanche1, input2: val.target.value})}/>
                                </div>

                                <p className="menu-hours-p">à</p>

                                <div className="menu-flexed">
                                    <input style={dimanche1.select ? {border: '1px solid #016589'} : null} disabled={dimanche1.select ? null : "disabled" } type="text" value={dimanche1.input3} maxLength="2" onChange={(val) => setDimanche1({ ...dimanche1, input3: val.target.value})}/>
                                    <p className="menu-hours-p">:</p>
                                    <input style={dimanche1.select ? {border: '1px solid #016589'} : null} disabled={dimanche1.select ? null : "disabled" } type="text" value={dimanche1.input4} maxLength="2" onChange={(val) => setDimanche1({ ...dimanche1, input4: val.target.value})}/>
                                </div>

                                <p className="menu-hours-p">-</p>

                                <div className="menu-flexed">
                                    <input style={dimanche1.select ? {border: '1px solid #016589'} : null} disabled={dimanche1.select ? null : "disabled" } type="text" value={dimanche1.input5} maxLength="2" onChange={(val) => setDimanche1({ ...dimanche1, input5: val.target.value})}/>
                                    <p className="menu-hours-p">:</p>
                                    <input style={dimanche1.select ? {border: '1px solid #016589'} : null} disabled={dimanche1.select ? null : "disabled" } type="text" value={dimanche1.input6} maxLength="2" onChange={(val) => setDimanche1({ ...dimanche1, input6: val.target.value})}/>
                                </div>

                                <p className="menu-hours-p">à</p>

                                <div className="menu-flexed">
                                    <input disabled={dimanche1.select ? null : "disabled" } type="text" value={dimanche1.input7} maxLength="2" onChange={(val) => setDimanche1({ ...dimanche1, input7: val.target.value})}/>
                                    <p className="menu-hours-p">:</p>
                                    <input disabled={dimanche1.select ? null : "disabled" } type="text" value={dimanche1.input8} maxLength="2" onChange={(val) => setDimanche1({ ...dimanche1, input8: val.target.value})}/>
                                </div>
                            </div>

                            <div className="menu-hours-allbutton menu-flexed">
                                <p className={ dimanche1.select ? "menu-hours-button menu-button-select" : "menu-hours-button menu-button-noselect" } onClick={() => handleCopy(dimanche1, 'dimanche')}>Copier</p>
                                <p className={ dimanche1.select ? "menu-hours-button menu-button-select" : "menu-hours-button menu-button-noselect" } onClick={() => handlePaste(setDimanche1, 'dimanche')}>Coller</p>
                                <p className={ dimanche1.select ? "menu-hours-button menu-button-select" : "menu-hours-button menu-button-noselect" } onClick={() => handleApply(dimanche1, 'dimanche')}>
                                    Appliquer à tous
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="menu-popup" style={{display: 'none', position: 'fixed', bottom: '10px', width: 'auto', right: '10px', backgroundColor: '#016589', padding: '10px', borderRadius: '10px', color: 'white'}}></div>
                
            </div>
        </div>
    );
}

export default MenuHours;