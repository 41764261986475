import React, { useEffect, useState } from "react";
import {
  useForm,
  FormProvider,
  useWatch,
  useFormContext,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Skeleton } from "primereact/skeleton";
import { InputText } from "primereact/inputtext";
import ControlledInput from "../inputs/ControlledInput";
import { numbersOptions } from "../../static";
import { CheckIcon, XIcon } from "../../assets/svgs";
import { useQuery } from "@tanstack/react-query";
import { fetchNumbersByZone } from "../../services/numbers";
import NumberAvailability from "./NumberAvailability";
import { findNumberType } from "../../utils/numbers";
import { editNumberSchema } from "../../schema/editNumberSchema";
import { Dropdown } from "primereact/dropdown";

const EditNumber = ({ numberZone }) => {
  const [isAvailable, setIsAvailable] = useState(undefined);
  const [numberType, setNumberType] = useState(null);
  const { data: numbers, isLoading } = useQuery({
    queryKey: ["numbers", numberZone],
    queryFn: async () => {
      return await fetchNumbersByZone(numberZone);
    },
  });

  const methods = useForm({
    values: {
      numberStart: numbersOptions[numberZone][0],
      numberEnd: "",
    },
    resolver: zodResolver(editNumberSchema),
  });

  const { setValue } = useFormContext();

  const digitsChange = () => {
    const value = methods.getValues("numberEnd");
    setIsAvailable(undefined);
    setValue("number", undefined);
    setValue("editNumberType", undefined);
    if (value.length === 4) {
      const numberStart =
        methods.getValues("numberStart")?.replaceAll(" ", "") ?? "";
      const number = numberStart + value;
      const res = findNumberType(numbers, number, isLoading);
      if (Boolean(res?.number)) {
        setValue("number", res.number);
        setValue("editNumberType", res.type);
        setValue("numType", res.type);
        setIsAvailable(true);
        setNumberType(res.type);
      } else {
        setIsAvailable(false);
      }
    }
  };

  const numberEnd = useWatch({
    control: methods.control,
    name: "numberEnd",
  });

  useEffect(() => {
    digitsChange();
  }, [numberEnd, numberZone, digitsChange]);

  if (isLoading)
    return (
      <Skeleton className="w-12 mt-2 pt-2 w-full" height="20vh"></Skeleton>
    );

  return (
    <div className="grid w-12 mt-3 m-0 light-primary-bg border-round-lg p-3">
      <strong className="col-12 text-lg">Personalisez votre numéro</strong>
      <p className="col-12 py-0 text-sm md:text-base">
        1. Sélectionnez le début de votre numéro dans la liste
      </p>
      <p className="col-12 py-0 text-sm md:text-base">
        2. Saisissez les 4 derniers chiffres souhaités
      </p>
      <FormProvider {...methods}>
        <form className="col-12 grid w-12 mt-1 justify-content-end">
          <div className="col-6 md:col-4">
            <ControlledInput name={"numberStart"} control={methods.control}>
              <Dropdown
                className="w-full h-3rem align-items-center border-round border-1 text-black-alpha-90	 font-bold bg-white pl-3 md:p-4"
                options={numbersOptions[numberZone]}
              />
            </ControlledInput>
          </div>
          <div className="col-6 md:col-8">
            <span className="p-input-icon-right flex align-items-center">
              {isAvailable === true ? (
                <CheckIcon fill="green" className="pi-center-icon-input" />
              ) : isAvailable === false ? (
                <XIcon className="pi-center-icon-input" />
              ) : null}
              <ControlledInput
                className="w-12"
                name="numberEnd"
                control={methods.control}
              >
                <InputText
                  type="text"
                  className="w-full h-3rem rounded-md p-2 md:p-4"
                  placeholder="1234"
                  maxLength={4}
                  keyfilter={"int"}
                  onChange={(e) => console.log("change")}
                />
              </ControlledInput>
            </span>
          </div>
          <div className="col-12 md:col-8 py-0">
            <NumberAvailability
              isAvailable={isAvailable}
              numberType={numberType}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default EditNumber;
