import { useEffect, useState } from "react";

import "../../assets/css/App.css";

import Header from "../../composants/Header";
import ProgressBar from "../../composants/ProgressBar";
import Arrow from "../../composants/Arrow";
import Title from "../../composants/Title";
import Button from "../../composants/Button";
import MenuContainer from "../../composants/Config/Menu/MenuContainer";
import { useNavigate } from "react-router-dom";
import routes from "../../router/routes";

function Menu() {
  const [link, setLink] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    let localValue = sessionStorage.getItem("choix_menu");

    if (localValue === "1") {
      navigate(routes.configInfos);
    } else if (localValue === "2") {
      setLink(routes.configRenvoi);
    } else if (localValue === "3") {
      setLink(routes.configChoix);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="Home">
      <Header lien={routes.index} />
      <div className="body-config">
        <div className="container-config">
          <ProgressBar before={50} percentage={75} etape={3} />
          <Arrow lien={link} />
          <Title title={"Réglage de votre standard"} />
          <MenuContainer />
          <Button lien={routes.configMandat} />
        </div>
      </div>
    </div>
  );
}

export default Menu;
