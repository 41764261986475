import "../assets/css/Success.css";
import logo from "../assets/logo/logo.svg";
import { useSearchParams } from "react-router-dom";
import ControlledInput from "../composants/inputs/ControlledInput";
import { InputText } from "primereact/inputtext";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { cancelCommandeSchema } from "../schema/cancelCommandeSchema";
import { InputMask } from "primereact/inputmask";
import MainButton from "../composants/inscription/MainButton";
import { onCancelSubscription } from "../querys/stripe";
import { toast } from "react-toastify";

const CancelCommande = () => {
  const [searchParams] = useSearchParams();
  const commandeID = Number(searchParams.get("commande"));

  const getFormErrorMessage = (name, center = false) => {
    const {
      formState: { errors },
    } = methods;
    return errors[name] ? (
      <small
        className={`p-error px-3 pb-2 w-full lg:max-w-30rem ${
          center ? "text-center" : ""
        }`}
      >
        {errors[name].message}
      </small>
    ) : (
      <small className="p-error px-3">&nbsp;</small>
    );
  };

  const methods = useForm({
    values: {
      commande: commandeID,
    },
    resolver: zodResolver(cancelCommandeSchema),
  });

  const onSubmit = async (data) => {
    const res = await onCancelSubscription(data);
    if (res?.success) {
      toast.success("Abonnement annuleé avec success", {
        position: "bottom-right",
      });
    } else {
      methods.setError("root", {
        message: res?.message,
      });
    }
  };

  return (
    <div className="success-commande">
      <div className="div_img">
        <img width="400rem" height="auto" src={logo} alt="logo-colortelecom" />
      </div>
      <form
        className="w-full p-4 flex flex-column align-items-center"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <div className="p-2 w-full lg:w-8 md:w-10">
          <h2 className="text-center text-gray-800 my-2">
            Résilier mon essai gratuit
          </h2>
          <ul>
            <ol className="text-red-500">
              1. Il est inutile de résilier :
              <ul className="text-gray-700 px-6">
                <li>
                  Si vous souhaitez activer votre abonnement sans engagement
                  avant la fin de la période d’essai : La période d’essai se
                  transformera automatiquement en abonnement sans engagement au
                  tarif sélectionné au terme des 7 jours.
                </li>
                <li>
                  Si un autre forfait vous convient : Il vous suffit de changer
                  votre forfait en nous contactant au{" "}
                  <strong className="text-black-alpha-90 white-space-nowrap	">
                    01 87 200 200{" "}
                  </strong>
                </li>
                <li>
                  Si vous souhaitez changer de titulaire de ligne : Il vous
                  suffit de nous envoyer un email à{" "}
                  <strong className="text-black-alpha-90 white-space-nowrap	">
                    support@standardenligne.fr{" "}
                  </strong>{" "}
                  avec les informations du nouveau titulaire.
                </li>
              </ul>
            </ol>
            <ol className="text-red-500 my-4">
              2. Vous souhaitez quand-même mettre fin à votre période d’essai ?
              <p className="text-gray-700	">
                Si ces alternatives ne correspondent pas à vos attentes, vous
                pouvez résilier directement en ligne votre essai gratuit avant
                la fin des 7 jours
              </p>
              <p className="text-gray-700	my-2">
                {" "}
                À savoir avant de résilier votre période d’essai gratuite :
              </p>
              <ul className="text-gray-700	my-2 px-6">
                <li>Vous perdrez votre numéro Standard en ligne</li>
                <li>Vous perdrez votre configuration actuelle</li>
                <li>
                  Votre compte client sera automatiquement désactivé et résilié
                </li>
              </ul>
            </ol>
          </ul>
        </div>
        {getFormErrorMessage("root", true)}
        <ControlledInput name="email" control={methods.control}>
          <InputText
            id="email"
            type="email"
            className={`w-full h-3rem px-3 text-black-alpha-80 lg:max-w-30rem	 ${
              methods.formState.errors.email && "p-invalid"
            }`}
            placeholder="Email"
          />
        </ControlledInput>
        {getFormErrorMessage("email")}
        <ControlledInput name="telephone" control={methods.control}>
          <InputMask
            id="telephone"
            type="text"
            className={`w-full h-3rem px-3 text-black-alpha-80 lg:max-w-30rem ${
              methods.formState.errors.telephone && "p-invalid"
            }`}
            keyfilter={"int"}
            placeholder="Numéro commandé"
            mask="99 99 99 99 99"
          />
        </ControlledInput>
        {getFormErrorMessage("telephone")}
        <div className="flex relative w-12 align-items-center justify-content-center lg:justify-content-center mt-4 mb-3">
          <MainButton
            label="Je confirme la résiliation de l’offre d’essai gratuite"
            disabled={methods.formState.isSubmitting}
          />
        </div>
      </form>
    </div>
  );
};

export default CancelCommande;
