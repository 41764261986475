import sendRequest from "../API";
import API_Routes from "../API/API_Routes";

const createAbonnement = async (data, signal) => {
  try {
    const response = await sendRequest(
      API_Routes.createAbonnement,
      "POST",
      data,
      signal
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export { createAbonnement };
