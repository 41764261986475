import { useQuery } from "@tanstack/react-query";
import { fetchAllOffers } from "../services/offers";
const useOffers = (queryKey = "AllOffers", callBack = null) => {
  return useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      const response = await fetchAllOffers();
      const sorted = {
        forfaitM: response["forfaitM"].sort((a, b) => a.id - b.id),
        forfaitA: response["forfaitA"].sort((a, b) => a.id - b.id),
      };
      if (callBack) return callBack(sorted);
      return sorted;
    },
  });
};

export default useOffers;
