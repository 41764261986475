import React from "react";
import logo_pack_fixe from '../../assets/logo/logo_pack_fixe.png';
import logo_pack_pro from '../../assets/logo/logo_pack_pro.png';
import logo_pack_virtuel from '../../assets/logo/logo_pack_virtuel.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import "../../assets/css/page-tarifs/TableauResponsive.css";
import { Accordion, AccordionTab } from "primereact/accordion";

export default function TableauResponsive() {
  return (
    <div className="table-container mt-8">
      <table className="responsive-table">
        <thead>
          <tr className="">
            <th className="td-bg-standard">
              <img src={logo_pack_virtuel} alt="Pack Virtuel" className="logo" />
              <div>Pack Virtuel</div>
            </th>
            <th className="td-bg-fixe">
              <img src={logo_pack_fixe} alt="Pack Fixe" className="logo" />
              <div>Pack Fixe</div>
            </th>
            <th className="td-bg-pro">
              <img src={logo_pack_pro} alt="Pack Pro" className="logo" />
              <div>Pack Pro</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="3">
              <Accordion>
                <AccordionTab header="Numéros & utilisateurs">
                  <table className="inner-table">
                    <tbody>
                      <td colSpan="3" className="text-left first"><b>1 numéro fixe par entreprise 12 pays inclus</b></td>
                      <tr className="mt-2">
                        <td className="td-bg-standard"><FontAwesomeIcon className="check-color-standard" icon={faCheck} /></td>
                        <td className="td-bg-fixe"><FontAwesomeIcon className="check-color-fixe" icon={faCheck} /></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                      <td colSpan="3" className="text-left second"><b>1 numéro fixe par utilisateur</b></td>
                      <tr className="">
                        <td className="td-bg-standard"><FontAwesomeIcon className="check-color-standard" icon={faCheck} /></td>
                        <td className="td-bg-fixe"><FontAwesomeIcon className="check-color-fixe" icon={faCheck} /></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                      <td colSpan="3" className="text-left second"><b>Numéros internationaux</b></td>
                      <tr className="">
                        <td className="td-bg-standard"><FontAwesomeIcon className="check-color-standard" icon={faCheck} /></td>
                        <td className="td-bg-fixe"><FontAwesomeIcon className="check-color-fixe" icon={faCheck} /></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                      <td colSpan="3" className="text-left second"><b>Utilisateurs illimités</b></td>
                      <tr className="">
                        <td className="td-bg-standard"><FontAwesomeIcon className="check-color-standard" icon={faCheck} /></td>
                        <td className="td-bg-fixe"><FontAwesomeIcon className="check-color-fixe" icon={faCheck} /></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                    </tbody>
                  </table>
                </AccordionTab>
              </Accordion>
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              <Accordion>
                <AccordionTab header="Appels">
                  <table className="inner-table">
                    <tbody>
                      <td colSpan="3" className="text-left first"><b>Appels entrants avec redirection d’appels vers des numéros externes</b></td>
                      <tr className="mt-2">
                        <td className="td-bg-standard"><FontAwesomeIcon className="check-color-standard" icon={faCheck} /></td>
                        <td className="td-bg-fixe"></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                      <td colSpan="3" className="text-left first"><b>Appels entrants illimités</b></td>
                      <tr className="mt-2">
                        <td className="td-bg-standard"></td>
                        <td className="td-bg-fixe"></td>
                        <td className="td-bg-pro"><FontAwesomeIcon className="check-color-pro" icon={faCheck} /></td>
                      </tr>
                      <td colSpan="3" className="text-left second"><b>Appels sortants vers Fixes et Mobiles en France Métropolitaine</b></td>
                      <tr className="">
                        <td className="td-bg-standard"></td>
                        <td className="td-bg-fixe"><FontAwesomeIcon className="check-color-fixe" icon={faCheck} /></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                      <td colSpan="3" className="text-left second"><b>Redirections d'appels vers destinations internationales</b></td>
                      <tr className="">
                        <td className="td-bg-standard"><FontAwesomeIcon className="check-color-standard" icon={faCheck} /></td>
                        <td className="td-bg-fixe"></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                      <td colSpan="3" className="text-left second"><b>Appels sortants vers destinations internationales</b></td>
                      <tr className="">
                        <td className="td-bg-standard"></td>
                        <td className="td-bg-fixe"><FontAwesomeIcon className="check-color-fixe" icon={faCheck} /></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                      <td colSpan="3" className="text-left second"><b>Notification d'appels</b></td>
                      <tr className="">
                        <td className="td-bg-standard"><FontAwesomeIcon className="check-color-standard" icon={faCheck} /></td>
                        <td className="td-bg-fixe"><FontAwesomeIcon className="check-color-fixe" icon={faCheck} /></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                      <td colSpan="3" className="text-left second"><b>Application mobile et ordinateur</b></td>
                      <tr className="">
                        <td className="td-bg-standard"></td>
                        <td className="td-bg-fixe"><FontAwesomeIcon className="check-color-fixe" icon={faCheck} /></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                      <td colSpan="3" className="text-left second"><b>Réception de fax par e-mail</b></td>
                      <tr className="">
                        <td className="td-bg-standard"><FontAwesomeIcon className="check-color-standard" icon={faCheck} /></td>
                        <td className="td-bg-fixe"><FontAwesomeIcon className="check-color-fixe" icon={faCheck} /></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                    </tbody>
                  </table>
                </AccordionTab>
              </Accordion>
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              <Accordion>
                <AccordionTab header="SMS">
                  <table className="inner-table">
                    <tbody>
                      <td colSpan="3" className="text-left first"><b>Envoi de SMS</b></td>
                      <tr className="mt-2">
                        <td className="td-bg-standard">Option</td>
                        <td className="td-bg-fixe">Option</td>
                        <td className="td-bg-pro"></td>
                      </tr>
                    </tbody>
                  </table>
                </AccordionTab>
              </Accordion>
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              <Accordion>
                <AccordionTab header="Standard Téléphonique">
                  <table className="inner-table">
                    <tbody>
                      <td colSpan="3" className="text-left first"><b>Pré-décroché</b></td>
                      <tr className="mt-2">
                        <td className="td-bg-standard"><FontAwesomeIcon className="check-color-standard" icon={faCheck} /></td>
                        <td className="td-bg-fixe"><FontAwesomeIcon className="check-color-fixe" icon={faCheck} /></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                      <td colSpan="3" className="text-left first"><b>Musique d'attente</b></td>
                      <tr className="mt-2">
                        <td className="td-bg-standard"><FontAwesomeIcon className="check-color-standard" icon={faCheck} /></td>
                        <td className="td-bg-fixe"><FontAwesomeIcon className="check-color-fixe" icon={faCheck} /></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                      <td colSpan="3" className="text-left first"><b>Gestion des horaires</b></td>
                      <tr className="mt-2">
                        <td className="td-bg-standard"><FontAwesomeIcon className="check-color-standard" icon={faCheck} /></td>
                        <td className="td-bg-fixe"><FontAwesomeIcon className="check-color-fixe" icon={faCheck} /></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                      <td colSpan="3" className="text-left first"><b>Menu Multichoix</b></td>
                      <tr className="mt-2">
                        <td className="td-bg-standard"><FontAwesomeIcon className="check-color-standard" icon={faCheck} /></td>
                        <td className="td-bg-fixe"><FontAwesomeIcon className="check-color-fixe" icon={faCheck} /></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                      <td colSpan="3" className="text-left first"><b>File d'attente</b></td>
                      <tr className="mt-2">
                        <td className="td-bg-standard"><FontAwesomeIcon className="check-color-standard" icon={faCheck} /></td>
                        <td className="td-bg-fixe"><FontAwesomeIcon className="check-color-fixe" icon={faCheck} /></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                      <td colSpan="3" className="text-left first"><b>Réception de fax par e-mail</b></td>
                      <tr className="mt-2">
                        <td className="td-bg-standard"><FontAwesomeIcon className="check-color-standard" icon={faCheck} /></td>
                        <td className="td-bg-fixe"><FontAwesomeIcon className="check-color-fixe" icon={faCheck} /></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                    </tbody>
                  </table>
                </AccordionTab>
              </Accordion>
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              <Accordion>
                <AccordionTab header="Suivi Statistique">
                  <table className="inner-table">
                    <tbody>
                      <td colSpan="3" className="text-left first"><b>Journal d'appel interactif</b></td>
                      <tr className="mt-2">
                        <td className="td-bg-standard"><FontAwesomeIcon className="check-color-standard" icon={faCheck} /></td>
                        <td className="td-bg-fixe"><FontAwesomeIcon className="check-color-fixe" icon={faCheck} /></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                      <td colSpan="3" className="text-left first"><b>Enregistrement d'appels</b></td>
                      <tr className="mt-2">
                        <td className="td-bg-standard">Option</td>
                        <td className="td-bg-fixe">Option</td>
                        <td className="td-bg-pro"></td>
                      </tr>
                      <td colSpan="3" className="text-left first"><b>Click to call</b></td>
                      <tr className="mt-2">
                        <td className="td-bg-standard"></td>
                        <td className="td-bg-fixe"><FontAwesomeIcon className="check-color-fixe" icon={faCheck} /></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                      <td colSpan="3" className="text-left first"><b>Choix du numéro de téléphone sortant</b></td>
                      <tr className="mt-2">
                        <td className="td-bg-standard"></td>
                        <td className="td-bg-fixe"><FontAwesomeIcon className="check-color-fixe" icon={faCheck} /></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                      <td colSpan="3" className="text-left first"><b>Changement d'appareil en cours de communication</b></td>
                      <tr className="mt-2">
                        <td className="td-bg-standard"></td>
                        <td className="td-bg-fixe"><FontAwesomeIcon className="check-color-fixe" icon={faCheck} /></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                      <td colSpan="3" className="text-left first"><b>Messages vocaux par email</b></td>
                      <tr className="mt-2">
                        <td className="td-bg-standard"><FontAwesomeIcon className="check-color-standard" icon={faCheck} /></td>
                        <td className="td-bg-fixe"><FontAwesomeIcon className="check-color-fixe" icon={faCheck} /></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                      <td colSpan="3" className="text-left first"><b>Statistiques</b></td>
                      <tr className="mt-2">
                        <td className="td-bg-standard"><FontAwesomeIcon className="check-color-standard" icon={faCheck} /></td>
                        <td className="td-bg-fixe"><FontAwesomeIcon className="check-color-fixe" icon={faCheck} /></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                    </tbody>
                  </table>
                </AccordionTab>
              </Accordion>
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              <Accordion>
                <AccordionTab header="Outils collaboratifs">
                  <table className="inner-table">
                    <tbody>
                      <td colSpan="3" className="text-left first"><b>Répertoire de contacts partagés entre utilisateurs</b></td>
                      <tr className="mt-2">
                        <td className="td-bg-standard"><FontAwesomeIcon className="check-color-standard" icon={faCheck} /></td>
                        <td className="td-bg-fixe"><FontAwesomeIcon className="check-color-fixe" icon={faCheck} /></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                      <td colSpan="3" className="text-left first"><b>Notes d'appels</b></td>
                      <tr className="mt-2">
                        <td className="td-bg-standard"><FontAwesomeIcon className="check-color-standard" icon={faCheck} /></td>
                        <td className="td-bg-fixe"><FontAwesomeIcon className="check-color-fixe" icon={faCheck} /></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                      <td colSpan="3" className="text-left first"><b>Sonneries personnalisées</b></td>
                      <tr className="mt-2">
                        <td className="td-bg-standard"></td>
                        <td className="td-bg-fixe"><FontAwesomeIcon className="check-color-fixe" icon={faCheck} /></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                      <td colSpan="3" className="text-left first"><b>Messagerie collaborative</b></td>
                      <tr className="mt-2">
                        <td className="td-bg-standard"></td>
                        <td className="td-bg-fixe"><FontAwesomeIcon className="check-color-fixe" icon={faCheck} /></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                      <td colSpan="3" className="text-left first"><b>Messagerie collaborative</b></td>
                      <tr className="mt-2">
                        <td className="td-bg-standard"></td>
                        <td className="td-bg-fixe"><FontAwesomeIcon className="check-color-fixe" icon={faCheck} /></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                    </tbody>
                  </table>
                </AccordionTab>
              </Accordion>
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              <Accordion>
                <AccordionTab header="Intégrations">
                  <table className="inner-table">
                    <tbody>
                      <td colSpan="3" className="text-left first"><b>E-mail</b></td>
                      <tr className="mt-2">
                        <td className="td-bg-standard"><FontAwesomeIcon className="check-color-standard" icon={faCheck} /></td>
                        <td className="td-bg-fixe"><FontAwesomeIcon className="check-color-fixe" icon={faCheck} /></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                      <td colSpan="3" className="text-left first"><b>Intégrations CRM natives</b></td>
                      <tr className="mt-2">
                        <td className="td-bg-standard"></td>
                        <td className="td-bg-fixe"><FontAwesomeIcon className="check-color-fixe" icon={faCheck} /></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                    </tbody>
                  </table>
                </AccordionTab>
              </Accordion>
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              <Accordion>
                <AccordionTab header="Support client">
                  <table className="inner-table">
                    <tbody>
                      <td colSpan="3" className="text-left first"><b>Portabilité de votre numéro historique</b></td>
                      <tr className="mt-2">
                        <td className="td-bg-standard"><FontAwesomeIcon className="check-color-standard" icon={faCheck} /></td>
                        <td className="td-bg-fixe"><FontAwesomeIcon className="check-color-fixe" icon={faCheck} /></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                      <td colSpan="3" className="text-left first"><b>Plateforme d'onboarding</b></td>
                      <tr className="mt-2">
                        <td className="td-bg-standard"><FontAwesomeIcon className="check-color-standard" icon={faCheck} /></td>
                        <td className="td-bg-fixe"><FontAwesomeIcon className="check-color-fixe" icon={faCheck} /></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                      <td colSpan="3" className="text-left first"><b>Support par e-mail et téléphone</b></td>
                      <tr className="mt-2">
                        <td className="td-bg-standard"><FontAwesomeIcon className="check-color-standard" icon={faCheck} /></td>
                        <td className="td-bg-fixe"><FontAwesomeIcon className="check-color-fixe" icon={faCheck} /></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                      <td colSpan="3" className="text-left first"><b>Responsable de compte dédié</b></td>
                      <tr className="mt-2">
                        <td className="td-bg-standard"><FontAwesomeIcon className="check-color-standard" icon={faCheck} /></td>
                        <td className="td-bg-fixe"><FontAwesomeIcon className="check-color-fixe" icon={faCheck} /></td>
                        <td className="td-bg-pro"></td>
                      </tr>
                    </tbody>
                  </table>
                </AccordionTab>
              </Accordion>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
