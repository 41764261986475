import axios from "axios";
import { toast } from "react-toastify";

const sendRequest = async (
  url,
  method = "get",
  data = null,
  signal = null,
  noToast = false
) => {
  try {
    const response = await axios({
      method,
      url,
      data,
      signal,
    });
    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
    } else {
      console.error("API call error:", error);
      const err = {
        message: error.response.data.message ?? "une erreur s'est produite",
        action: {
          method,
          url,
          data,
        },
      };
      if (!noToast)
        toast.error(err.message, {
          position: "bottom-right",
        });
      throw err; // You can handle or log the error as needed
    }
  }
};

export default sendRequest;
