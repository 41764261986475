const checkIban = async(iban) => {
    try {

        const check = await fetch(`https://openiban.com/validate/${iban}`)
        
        const response = await check.json()

        return response

    } catch(err) {
        throw err
    }
}

export default checkIban