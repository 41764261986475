import { z } from "zod";
import errorMessages from "./errorMessages";
export const cancelCommandeSchema = z.object({
  telephone: z.string({
    required_error: errorMessages.required,
  }),
  email: z
    .string({
      required_error: errorMessages.required,
    })
    .email({ message: errorMessages.email }),
  commande: z.number(),
});
