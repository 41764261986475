import "../assets/css/Error.css";
import logo from "../assets/logo/logo.svg";
import errorImg from "../assets/images/error-img.svg";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Maintain(props) {
  const navigate = useNavigate();

  useEffect(() => {
    if (!props.isError) {
      navigate("/Home");
    }
  });

  return (
    <>
      <div className="error-container">
        <img className="error-logo" src={logo} alt="" />
        <img className="error-img" src={errorImg} alt="" />
        <h3>Une maintenance est en cours</h3>
        <span>
          Veuillez nous excuser de la gène.
          <br /> vous pouvez aussi nous appeler
          <br />
          au <strong>01 87 200 200</strong>
        </span>
        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <a href="https://standardenligne.fr">
          <button className="error-btn">&#8617; Revenir sur le site</button>
        </a>
      </div>
    </>
  );
}

export default Maintain;
