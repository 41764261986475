import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../utils/Store";

import "../../assets/css/Config/Step/Mandat.css";

import picto_info from "../../assets/images/config/information.svg";
import checkIban from "../../helpers/checkIban";
import { useSearchParams } from "react-router-dom";

function MandatContainer() {
  const [searchParams] = useSearchParams();
  const configToken = searchParams.get("token");
  const { nextStep } = useContext(StoreContext);
  const commandeToken = JSON.parse(
    window.localStorage.getItem("commandeToken")
  );

  const [mandatState, setMandat] = useState({
    nom: Boolean(configToken) ? commandeToken?.client.nom : "",
    prenom: Boolean(configToken) ? commandeToken?.client.prenom : "",
    societe: Boolean(configToken) ? commandeToken?.client.nom_structure : "",
    iban: "",
    pays: Boolean(configToken) ? commandeToken?.client.pays : "",
    adresse: Boolean(configToken) ? commandeToken?.client.adresse : "",
    ville: Boolean(configToken) ? commandeToken?.client.ville : "",
    postal: Boolean(configToken) ? commandeToken?.client.code_postal : "",
  });

  const [ville, setVille] = useState([]);

  const [checkIbanState, setCheckIban] = useState("");

  const [popup, setPopup] = useState(false);

  const verif = async (val) => {
    setCheckIban(val.target.value.toUpperCase());

    const check = await checkIban(val.target.value);

    if (check.valid === true) {
      setMandat({ ...mandatState, iban: val.target.value.toUpperCase() });
    } else {
      setMandat({ ...mandatState, iban: "" });
    }
  };

  useEffect(() => {
    const stringyfy1 = JSON.stringify(mandatState);
    sessionStorage.setItem("mandat_infos", stringyfy1);

    if (
      mandatState.nom === "" ||
      mandatState.prenom === "" ||
      mandatState.societe === "" ||
      mandatState.iban === "" ||
      mandatState.pays === "" ||
      mandatState.adresse === "" ||
      mandatState.ville === "" ||
      mandatState.postal === ""
    ) {
      return nextStep[1](false);
    }

    return nextStep[1](true);

    // eslint-disable-next-line
  }, [mandatState]);

  const postalAPI = (val) => {
    if (
      val.target.value !== "" &&
      val.target.value.length >= 5 &&
      mandatState.pays === "France"
    ) {
      fetch(`https://geo.api.gouv.fr/communes?codePostal=${val.target.value}`)
        .then((response) => response.json())
        .then((data) => {
          if (data[0] === undefined) {
            setVille([]);
            setMandat({ ...mandatState, ville: "", postal: val.target.value });
          } else {
            setVille(data);
            setMandat({
              ...mandatState,
              postal: val.target.value,
              ville: data[0].nom,
            });
          }
        });
    } else {
      setVille([]);
      setMandat({ ...mandatState, ville: "", postal: "" });
    }
  };

  return (
    <div className="mandat-container">
      <p className="mandat-title">Mandat SEPA</p>
      <p className="mandat-subtitle">
        Pourquoi nous vous demandons de renseigner les informations liées à
        votre Relevé d'Identité Bancaire ?
        <span
          style={{ marginLeft: "10px" }}
          onMouseEnter={() => setPopup(true)}
          onMouseLeave={() => setPopup(false)}
        >
          <img
            style={{ position: "relative", top: "1px" }}
            src={picto_info}
            alt="picto_info_colortelecom"
            width="20px"
            height="auto"
          />{" "}
          Info
        </span>
      </p>
      {popup ? (
        <div
          onMouseEnter={() => setPopup(true)}
          onMouseLeave={() => setPopup(false)}
          style={{
            border: "1px solid #264A6E",
            backgroundColor: "#FFFFFF",
            padding: "10px",
            margin: "0px 5% 0 5%",
          }}
        >
          <p style={{ fontWeight: "bold", marginBottom: "10px" }}>
            Nous vous demandons de renseigner les informations liées à votre
            Relevé d’Identité Bancaire pour plusieurs raisons:
          </p>
          <p style={{ marginBottom: "10px" }}>
            &#62; Sécurité: Avec ces informations, nous pouvons attester
            légalement que vous, ou votre entreprise, association, êtes bien le
            titulaire de la ligne demandée. Nous devons nous assurer que
            l’entité qui est facturée de l’abonnement correspond à l’entité
            utilisatrice de la ligne.
          </p>
          <p>
            &#62; Sérénité: En nous transmettant les informations liées à votre
            Relevé d’Identité Bancaire, vous nous autorisez à présenter en
            prélèvement SEPA la tentative de débit par carte bancaire qui a
            échouée (sans frais supplémentaire). Ainsi, votre ligne ne sera pas
            suspendue pour défaut de paiement. Si nous ne rencontrons pas
            d’incident de paiement, nous continuerons de débiter votre
            abonnement selon sa périodicité sur le moyen de paiement renseigné
            lors de la commande (Carte Bancaire ou PayPal).
          </p>
        </div>
      ) : null}
      <div className="mandat-body">
        <div className="mandat-double">
          <input
            type="text"
            name="lname"
            placeholder="Nom*"
            required
            value={mandatState.nom}
            onChange={(val) =>
              setMandat({ ...mandatState, nom: val.target.value })
            }
          />
          <input
            type="text"
            name="fname"
            placeholder="Prénom*"
            required
            value={mandatState.prenom}
            onChange={(val) =>
              setMandat({ ...mandatState, prenom: val.target.value })
            }
          />
        </div>
        <div className="mandat-double">
          <input
            value={mandatState.societe}
            type="text"
            name="organization"
            placeholder="Nom De La Société (Comme Formulée Sur Le KBIS)*"
            required
            onChange={(val) =>
              setMandat({ ...mandatState, societe: val.target.value })
            }
          />
          <input
            value={checkIbanState}
            type="text"
            placeholder="IBAN*"
            style={mandatState.iban === "" ? { color: "red" } : null}
            required
            onChange={(val) => verif(val)}
          />
        </div>
        <div className="mandat-double">
          <input
            type="text"
            placeholder="Pays*"
            list="pays-select"
            required
            value={mandatState.pays}
            onChange={(val) =>
              setMandat({ ...mandatState, pays: val.target.value })
            }
          />
          <datalist id="pays-select">
            <option>France</option>
            <option>Allemagne</option>
            <option>Andorre</option>
            <option>Autriche</option>
            <option>Belgique</option>
            <option>Chypre</option>
            <option>Danemark</option>
            <option>Espagne</option>
            <option>Estonie</option>
            <option>Finlande</option>
            <option>Grèce</option>
            <option>Hongrie</option>
            <option>Irlande</option>
            <option>Italie</option>
            <option>Lettonie</option>
            <option>Lituanie</option>
            <option>Luxembourg</option>
            <option>Malte</option>
            <option>Norvège</option>
            <option>Pays-Bas</option>
            <option>Pologne</option>
            <option>Portugal</option>
            <option>République Tchèque</option>
            <option>Roumanie</option>
            <option>Royaume-Uni</option>
            <option>Slovaquie</option>
            <option>Slovénie</option>
            <option>Suède</option>
            <option>Suisse</option>
            <option>Turquie</option>
            <option>Argentine</option>
            <option>Australie</option>
            <option>Bangladesh</option>
            <option>Brésil</option>
            <option>Canada</option>
            <option>Chili</option>
            <option>Chine</option>
            <option>Colombie</option>
            <option>Corée du Sud</option>
            <option>États-Unis</option>
            <option>Guam</option>
            <option>Hong Kong</option>
            <option>Inde</option>
            <option>Indonésie</option>
            <option>Islande</option>
            <option>Israël</option>
            <option>Japon</option>
            <option>Malaisie</option>
            <option>Mexique</option>
            <option>Mongolie</option>
            <option>Nigéria</option>
            <option>Nouvelle Zélande</option>
            <option>Pakistan</option>
            <option>Panama</option>
            <option>Paraguay</option>
            <option>Pérou</option>
            <option>Porto-Rico</option>
            <option>Taiwan</option>
            <option>Venezuela</option>
          </datalist>

          <input
            type="text"
            name="address"
            placeholder="Adresse"
            value={mandatState.adresse}
            required
            onChange={(val) =>
              setMandat({ ...mandatState, adresse: val.target.value })
            }
          ></input>
        </div>
        <div className="mandat-double">
          <input
            type="text"
            name="zip"
            placeholder="Code postal*"
            required
            value={mandatState.postal}
            onChange={(val) => {
              if (mandatState.pays === "France") {
                postalAPI(val);
              } else {
                setMandat({ ...mandatState, postal: val.target.value });
              }
            }}
          ></input>

          <input
            type="text"
            placeholder="Ville*"
            list="ville-select"
            value={mandatState.ville || ""}
            required
            onChange={(val) =>
              setMandat({ ...mandatState, ville: val.target.value })
            }
          />

          <datalist id="ville-select">
            <option disabled>Ville*</option>
            {ville.map((x, i) => {
              return <option key={i}>{x.nom}</option>;
            })}
          </datalist>
        </div>
      </div>
    </div>
  );
}

export default MandatContainer;
