import '../assets/css/Arrow.css'
import arrow from '../assets/images/picto/right_arrow.svg';

const Arrow = (props) => {
    
  return (
    <div className="arrow">
        <a href={props.lien}>
            <img width="2%" height="2%" src={arrow} alt="arrow-color-telecom" className="arrow-img"/>
        </a>
    </div>
  );
}

export default Arrow;
