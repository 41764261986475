import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Dropdown } from 'primereact/dropdown';
import { Button } from "primereact/button";
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { useMediaQuery } from 'react-responsive';
import logo from '../../assets/logo/logo.png';
import { Dialog } from 'primereact/dialog';
import logoPackVirtuel from '../../assets/logo/logo_pack_virtuel.png';
import logoPackFixe from '../../assets/logo/logo_pack_fixe.png';
import logoPackPro from '../../assets/logo/logo_pack_pro.png';
import logo_virtuel_details from '../../assets/images/img-page-tarifs/logo-pack-details/logo_virtuel_details.png'
import logo_fixe_details from '../../assets/images/img-page-tarifs/logo-pack-details/logo_fixe_details.png'
import logo_pro_details from '../../assets/images/img-page-tarifs/logo-pack-details/logo_pro_details.png'
import "primeicons/primeicons.css";
import "../../assets/css/page-tarifs/Header.css"; // Assurez-vous de corriger le chemin
import routes from "../../router/routes";

export default function HeaderResponsive({ offers }) {
  const navigate = useNavigate();
  const toast = useRef(null);
  const [visible, setVisible] = useState(false);
  const inputFilter = /^[a-zA-Z0-9\s]*$/;
  const optionsValues = ["forfaitM", "forfaitA"];
  const [optionValue, setOptionValue] = useState(optionsValues[0]);
  const options = ["Mensuel", "Annuel"];
  const [value, setValue] = useState(options[0]);
  const [selectedPack, setSelectedPack] = useState(null);
  const [selectedPackDescription, setSelectedPackDescription] = useState("");
  const [price, setPrice] = useState(Number(offers[optionValue][0].prix));
  const [priceIllimites, setPriceIllimites] = useState(19.9);
  const isMobile = useMediaQuery({ maxWidth: 767 }); // Ajout de la requête média pour les écrans mobiles
  const [selectedOfferId, setSeletedOfferId] = useState(
    offers[optionValue][0].id
  );
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [firstname, setFirstName] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [lastname, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [selectedNbUser, setSelectedNbUser] = useState(null);



  const handleNavigateToHome = () => {
    urlParams.set("offer", selectedOfferId);
    navigate(`${routes.choixNumero}?${urlParams.toString()}`);
  };

  const handleButtonClick = (offer) => {
    setPrice(Number(offer.prix));
    setSeletedOfferId(offer.id);
  };

  useEffect(() => {
    setPriceIllimites(
      Number(
        offers[optionValue]?.filter(
          (item) => item?.nombre_heure === "Illimité"
        )[0]?.prix
      )
    );
  }, [value, offers, optionValue]);

  const pack = [
    { name: 'Pack Standard Virtuel', code: 'standard', logo: logoPackVirtuel, description: "Description du Pack Standard Virtuel" },
    { name: 'Pack Fixe VOIP', code: 'fixe', logo: logoPackFixe, description: "Description du Pack Fixe VOIP" },
    { name: 'Pack Business Pro', code: 'pro', logo: logoPackPro, description: "Description du Pack Business Pro" },
  ];

  const itemTemplate = (option) => {
    return (
      <div className="flex align-items-center gap-3 p-dropdown-item">
        <img src={option.logo} alt={option.name} className="dropdown-logo" />
        <span>{option.name}</span>
      </div>
    );
  };

  const handlePackChange = (e) => {
    setSelectedPack(e.value);
    setSelectedPackDescription(e.value.description);
  };

  const nbUser = [
    { name: '1 à 3 salariés', code: '1-3' },
    { name: '4 à 10 salariés', code: '4-10' },
    { name: '11 à 20 salariés', code: '11-20' },
    { name: '21 à 50 salariés', code: '21-50' },
    { name: '51 à 100 salariés', code: '51-100' },
    { name: '100 salariés et plus', code: '100+' }
  ];



  const envoyerRequete = async () => {
    const requestBody = {
      firstname,
      lastname,
      email,
      phone,
      company,
      nbUser: selectedNbUser
    };
    try {
      const request = await fetch("https://env-3387615.jcloud-ver-jpe.ik-server.com/api/v1/email/mail-business-pro", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const response = await request.json();

      if (response?.success) {
        setIsSent(true);
        toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Votre demande a bien été envoyée', life: 4000 });
      } else {
        toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de l\'envoi. Veuillez réessayer', life: 4000 });
      }
    } catch (error) {
      toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de l\'envoi. Veuillez réessayer', life: 4000 });
    }
  };

  const handleSubmit = () => {
    // Vérifier si tous les champs obligatoires sont remplis
    if (!firstname || !lastname || !email || !phone || !company || !selectedNbUser) {
      alert("Veuillez remplir tous les champs obligatoires.");
      return; // Arrêter l'exécution si un champ obligatoire est vide
    }

    setIsSending(true); // Mettre à jour l'état pour indiquer que l'envoi est en cours
    // Envoyer les informations vers la fonction Offres
    envoyerRequete().then(() => {
      setIsSending(false); // Mettre à jour l'état pour indiquer que l'envoi est terminé
      setVisible(false); // Fermer le dialogue après l'envoi
    });
  };

  const handleDropdownProduct = (e) => {
    setSelectedProduct(e.value);
    window.location.href = e.value.url; // Rediriger vers la nouvelle URL dans le même onglet
  };

  const handleDropdownChange = (e) => {
    setSelectedNbUser(e.value.name);
    // Stocker seulement le nom sélectionné
  };

  const products = [
    { name: 'Pack Standard Virtuel', url: 'https://www.standardenligne.fr/pack-standard-virtuel/' },
    { name: 'Pack Fixe VOIP', url: 'https://www.standardenligne.fr/pack-fixe-illimite/' },
    { name: 'Pack Business Pro', url: 'https://www.standardenligne.fr/pack-business-pro' }
  ];

  const dropdownProduct = {
    border: 'none',
    color: '#333',
    outline: 'none',
    boxShadow: '0 2px 5px #fff',

  };


  const showDialog = () => {
    setVisible(true);
  };

  const hideDialog = () => {
    setVisible(false);
  };

  return (
    <>
      <div className="header">
        <div className="flex flex-wrap flex-row align-items-center justify-content-around mt-5">
          <div className="">
            <img src={logo} className="" alt="" />
          </div>
          <div className="flex flex-wrap flex-row justify-content-center align-items-center gap-6">
            <Dropdown
              style={dropdownProduct}
              value={selectedProduct}
              onChange={handleDropdownProduct}
              options={products}
              optionLabel="name"
              placeholder="Produit"
              className="p-2"
            />
            <b><p>Tarifs</p></b>
          </div>
          <div className="glimmer-orange"></div>
          <div className="flex flex-wrap flex-row align-items-center gap-6">
          </div>
        </div>
        <p style={{ fontSize: '25px', fontWeight: '600' }} className="text-center mt-8 mb-5">La solution de communication complète pour votre entreprise</p>
        <div className="">
          <div className="flex justify-content-center align-items-center">
            <div className="flex select-button-background align-items-center">
              <div className="flex tooltip-container align-items-center">
              </div>
              <div className="flex select-button justify-content-center align-items-center">
                <button
                  className={`monthly-annual-button mensuel-button ${value === "Mensuel" ? "active" : ""
                    }`}
                  onClick={() => {
                    setValue("Mensuel");
                    setOptionValue(optionsValues[0]);
                    handleButtonClick(offers["forfaitM"][0]);
                  }}
                >
                  Mensuel
                </button>
                <button
                  className={`monthly-annual-button annual-button mensuel-button annuel-tooltip ${value === "Annuel" ? "active" : ""
                    }`}
                  onClick={() => {
                    setValue("Annuel");
                    setOptionValue(optionsValues[1]);
                    handleButtonClick(offers["forfaitA"][0]);
                  }}
                >
                  Annuel <small className="ml-1">2 mois offerts</small>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="mr-4 ml-4 mt-5">
          <Dropdown
            value={selectedPack}
            onChange={handlePackChange}
            options={pack}
            optionLabel="name"
            placeholder="Nos solutions"
            className="w-full md:w-14rem dropdown-pack"
            itemTemplate={itemTemplate}
          />
        </div>
        <div className="pack-info mt-5">
          {selectedPack && selectedPack.code === "standard" && (
            <div className="flex flex-wrap flex-row align-items-center pack-details p-5 pack-details-standard">
              <div className="flex">
                <div className="flex flex-column gap-3">
                  <div className="flex flex-row align-items-center justify-content-center gap-3">
                    <img src={logo_virtuel_details} alt="" />
                    <div className="">
                      <p className="pack-title">Pack Standard Virtuel</p>
                      <p className="subtitle">Je démarre</p>
                    </div>
                  </div>
                  <div className="flex text-center">
                    <p>Adoptez la téléphonie cloud, simple, flexible et adaptée aux besoins de votre entreprise</p>
                  </div>
                  <div className="p-4 price-virtuel-pack">
                    <div className="flex justify-content-center align-items-center flex-column gap-0">
                      A partir de<br />
                      <p className="pack-price">{price.toFixed(2)}€<small style={{ fontSize: '14px' }}>{value === "Annuel" ? "HT/an" : "HT/mois"}</small></p>
                    </div>
                    <div className="flex justify-content-center">
                      <p className="trialButton w-full text-center" onClick={handleNavigateToHome}>Essai gratuit</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-column gap-4 list-details-offre mt-4">
                <div className="flex flex-row align-items-center gap-5 f-bold">
                  <i className="pi pi-check" style={{ color: '#00BD9D', fontSize: '1.8rem' }}></i>
                  <p>Standard Téléphonique tout compris avec renvoi d’appel vers vos lignes fixes et mobiles</p>
                </div>
                <div className="flex flex-row align-items-center gap-5 f-bold">
                  <i className="pi pi-check" style={{ color: '#00BD9D', fontSize: '1.8rem' }}></i>
                  <p>Un numéro local (01, 02), national (09) pour votre entreprise ou conservez le votre</p>
                </div>
                <div className="flex flex-row align-items-center gap-5 f-bold">
                  <i className="pi pi-check" style={{ color: '#00BD9D', fontSize: '1.8rem' }}></i>
                  <p>Activation 100% en ligne instantanée</p>
                </div>
                <div className="flex flex-row align-items-center gap-5 f-bold">
                  <i className="pi pi-check" style={{ color: '#00BD9D', fontSize: '1.8rem' }}></i>
                  <p>Sans engagement</p>
                </div>
              </div>
            </div>
          )}
          {selectedPack && selectedPack.code === "fixe" && (
            <div className="flex flex-wrap flex-row align-items-center pack-details p-5 pack-details-fixe">
              <div className="flex">
                <div className="flex flex-column gap-3">
                  <div className="flex flex-row align-items-center justify-content-center gap-3">
                    <img src={logo_fixe_details} alt="" />
                    <div className="">
                      <p className="pack-title">Pack Fixe VOIP</p>
                      <p className="subtitle">Je développe</p>
                    </div>
                  </div>
                  <div className="flex text-center">
                    <p>Adoptez la téléphonie fixe virtuelle conçue pour répondre aux besoins de votre entreprise</p>
                  </div>
                  <div className="p-4 price-fixe-pack">
                    <div className="flex justify-content-center align-items-center flex-column gap-0">
                      A partir de<br />
                      <p className="pack-price">{priceIllimites.toFixed(2)}€<small style={{ fontSize: '14px' }}>{value === "Annuel" ? "HT/an" : "HT/mois"}</small></p>
                    </div>
                    <div className="flex justify-content-center">
                      <p style={{ backgroundColor: '#1757FF' }} className="trialButton w-full text-center" onClick={() =>
                      (window.location.href = `${process.env.REACT_APP_DOMAINE_CENTREX
                        }${value === "Annuel"
                          ? "?isAnnuel=true"
                          : "?isAnnuel=false"
                        }`)
                      }>Essai gratuit</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-column gap-4 f-bold list-details-offre mt-4">
                <div className="flex flex-row align-items-center gap-5">
                  <i className="pi pi-check" style={{ color: '#1757FF', fontSize: '1.8rem' }}></i>
                  <p>Standard Téléphonique Virtuel tout inclus</p>
                </div>
                <div className="flex flex-row align-items-center gap-5">
                  <i className="pi pi-check" style={{ color: '#1757FF', fontSize: '1.8rem' }}></i>
                  <p>Un numéro local (01, 02), national (09) pour votre entreprise ou conservez le votre</p>
                </div>
                <div className="flex flex-row align-items-center gap-5">
                  <i className="pi pi-check" style={{ color: '#1757FF', fontSize: '1.8rem' }}></i>
                  <p>Appels inclus vers Fixes et Mobiles <span className="link-condition cursor-pointer" onClick={showDialog}>*voir conditions</span>
                  </p>
                  <Dialog modal className="dialog-responsive" header="Conditions liées au appels sortants" visible={visible} onHide={hideDialog}>
                    <p className="">Appels sortants inclus limité à 100 correspondants différents maximum par mois par utilisateur et 1h maximum par appel (hors coût du services de n° spéciaux,  destinations internationales non incluses et renvoi d’appel vers numéros externes). Au delà, facturation hors forfait au tarif de 0,15€/min HT</p>
                  </Dialog>
                </div>
                <div className="flex flex-row align-items-center gap-5">
                  <i className="pi pi-check" style={{ color: '#1757FF', fontSize: '1.8rem' }}></i>
                  <p>Application mobile et ordinateur</p>
                </div>
                <div className="flex flex-row align-items-center gap-5">
                  <i className="pi pi-check" style={{ color: '#1757FF', fontSize: '1.8rem' }}></i>
                  <p>Activation 100% en ligne instantanée</p>
                </div>
                <div className="flex flex-row align-items-center gap-5">
                  <i className="pi pi-check" style={{ color: '#1757FF', fontSize: '1.8rem' }}></i>
                  <p>Sans engagement</p>
                </div>
                <p onClick={showDialog} className="cursor-pointer link-condition">Conditions liées aux appels sortants</p>
                <Dialog modal className="dialog-responsive" header="Conditions liées au appels sortants" visible={visible} onHide={hideDialog}>
                  <p className="">Appels sortants inclus limité à 100 correspondants différents maximum par mois par utilisateur et 1h maximum par appel (hors coût du services de n° spéciaux,  destinations internationales non incluses et renvoi d’appel vers numéros externes). Au delà, facturation hors forfait au tarif de 0,15€/min HT</p>
                </Dialog>
              </div>
            </div>
          )}
          {selectedPack && selectedPack.code === "pro" && (
            <div className="flex flex-wrap flex-row align-items-center pack-details p-5 pack-details-pro">
              <div className="flex">
                <div className="flex flex-column gap-3">
                  <div className="flex flex-row align-items-center justify-content-center gap-3">
                    <img src={logo_pro_details} alt="" />
                    <div className="">
                      <p className="pack-title">Pack Business Pro</p>
                      <p className="subtitle">Je performe</p>
                    </div>
                  </div>
                  <div className="flex text-center">
                    <p>Adoptez la téléphonie cloud, simple, flexible et adaptée aux besoins de votre entreprise</p>
                  </div>
                  <div className="p-4 price-pro-pack">
                    <div className="flex justify-content-center align-items-center flex-column gap-0">
                      <b>Uniquement</b>
                      <p className="pack-price m-0">Sur devis</p>
                    </div>
                    <div>
                      <Button style={{ backgroundColor: 'black', borderRadius: '40px', fontWeight: '10px' }} className="w-full" label="Demander un devis" onClick={() => setVisible(true)} />
                      <Dialog header={
                        <>
                          <div className="flex flex-column align-items-center gap-2">
                            <h4>Pack Business Pro</h4>
                            <p style={{ fontSize: '13px', textAlign: 'justify' }}>La solution complète de téléphonie et de connectivité qui s’adapte à vos besoins.</p>
                          </div>
                        </>
                      } visible={visible} style={{ width: '50vw' }} onHide={() => { if (!visible) return; setVisible(false); }}>
                        <div className="text-justify mb-4">
                          <p>Vous souhaitez souscrire au Pack Business Pro ? <b>Demandez un devis ci-dessous</b></p>
                        </div>

                        <div className="flex flex-column gap-3">
                          <InputText
                            className="p-2"
                            keyfilter={inputFilter}
                            placeholder="Nom"
                            required
                            value={lastname}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                          <InputText
                            className="p-2"
                            keyfilter={inputFilter}
                            placeholder="Prénom"
                            required
                            value={firstname}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                          <InputText
                            className="p-2"
                            keyfilter="email"
                            required
                            placeholder="E-mail"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <InputMask
                            className="p-2"
                            mask="99 99 99 99 99"
                            placeholder="Téléphone"
                            keyfilter={/^[0-9]+$/}
                            minLength="10"
                            maxLength="10"
                            required
                            pattern="[0][0-9]{9}"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                          <InputText
                            required
                            className="p-2"
                            placeholder="Société"
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                          />
                          <Dropdown
                            required
                            value={nbUser.find(option => option.name === selectedNbUser) || null}
                            onChange={handleDropdownChange}
                            options={nbUser}
                            optionLabel="name"
                            placeholder="Nombre de salariés"
                            className="w-full p-2"
                          />
                          <div className="button-form">
                            <Button
                              type="submit"
                              className="w-full"
                              label={isSending || isSent ? (isSent ? "ENVOYÉ" : "ENVOI EN COURS") : "Confirmer"}
                              onClick={handleSubmit} // Appeler handleSubmit au clic sur le bouton
                              style={{ backgroundColor: 'black', fontWeight: "0" }}
                            />
                            <p style={{ fontSize: '12px' }} className="mt-3 text-center">COLOR TELECOM recueille ces informations pour gérer votre demande de devis, vous adresser ses offres commerciales et marketing, et suivre les performances de ses campagnes publicitaires.</p>
                          </div>
                        </div>
                      </Dialog>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-column gap-4 f-bold list-details-offre mt-4">
                <div className="flex flex-row align-items-center gap-5">
                  <i className="pi pi-check" style={{ color: '#0D081C', fontSize: '1.8rem' }}></i>
                  <p>Fibre Pro sécurisée très haut débit jusqu’à 1 Gb/s</p>
                </div>
                <div className="flex flex-row align-items-center gap-5">
                  <i className="pi pi-check" style={{ color: '#0D081C', fontSize: '1.8rem' }}></i>
                  <p>Standard Téléphonique Pro</p>
                </div>
                <div className="flex flex-row align-items-center gap-5">
                  <i className="pi pi-check" style={{ color: '#0D081C', fontSize: '1.8rem' }}></i>
                  <p>Appels illimités vers Fixes + Mobiles en France Métropolitaine, DOM-TOM Et +70 destinations à l’international
                  </p>
                </div>
                <div className="flex flex-row align-items-center gap-5">
                  <i className="pi pi-check" style={{ color: '#0D081C', fontSize: '1.8rem' }}></i>
                  <p>Application mobile et ordinateur</p>
                </div>
                <div className="flex flex-row align-items-center gap-5">
                  <i className="pi pi-check" style={{ color: '#0D081C', fontSize: '1.8rem' }}></i>
                  <p>Assistance et supervision proactive</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

