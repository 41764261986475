import { useEffect, useState } from "react";

import "../../assets/css/App.css";

import Header from "../../composants/Header.jsx";
import ProgressBar from "../../composants/ProgressBar.jsx";
import Arrow from "../../composants/Arrow";
import Title from "../../composants/Title";
import Button from "../../composants/Button";
import MandatContainer from "../../composants/Config/MandatContainer";
import routes from "../../router/routes.js";

const Mandat = () => {
  const [link, setLink] = useState("");

  useEffect(() => {
    let localValue = sessionStorage.getItem("choix_menu");

    if (localValue === "1") {
      setLink(routes.configRenvoi);
    } else if (localValue === "2") {
      setLink(routes.configMenu);
    } else if (localValue === "3") {
      setLink(routes.configMenu);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="Home">
      <Header lien={"/"} />
      <div className="body-config">
        <div className="container-config">
          <ProgressBar before={75} percentage={100} etape={4} />
          <Arrow lien={link} />
          <Title title={"Réglage de votre standard"} />
          <MandatContainer />
          <Button lien={routes.configFinish} />
        </div>
      </div>
    </div>
  );
};

export default Mandat;
