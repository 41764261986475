const routes = {
  index: "/",
  inscription: "/inscription",
  choixNumero: "/inscription/choix-numero",
  informationEntreprise: "/inscription/information-entreprise",
  inscriptionPayment: "/inscription/paiement",
  inscriptionSuccess: "/inscription/success",
  inscriptionError: "/inscription/error",
  successCheckout: "/success-checkout",
  success: "/success",
  cancel: "/cancel",
  cancelCommande: "/annuler-commande",
  error: "/error",
  oops: "/oops",
  configInfos: "/config/step1",
  configRenvoi: "/config/renvoi",
  configMandat: "/config/mandat",
  configMenu: "/config/menu",
  configChoix: "/config/choix",
  configFinish: "/config/finish",
  404: "*",
};

export default routes;
