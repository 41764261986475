import { RouterProvider } from "react-router-dom";
import "./assets/css/App.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import router from "./router";
import 'primeflex/primeflex.css';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StoreProvider from "./utils/Store";

const queryClient = new QueryClient();
export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <StoreProvider>
        <RouterProvider router={router} />
      </StoreProvider>
      <ToastContainer />
    </QueryClientProvider>
  );
}
