import { createClient } from "../services/client";
import { createCommande } from "../services/commande";

const onNumerChoose = (data) => {
  const { number, zone, pays, forfait, numType } = data;
  return {
    num: number.replaceAll(" ", ""),
    numType,
    zone,
    pays,
    forfait,
  };
};

const onCreateClient = async (data, signal = null) => {
  const {
    nom,
    prenom,
    email,
    telephone,
    nom_structure,
    code_postal,
    numeroRue,
    rue,
    ville,
    pays,
  } = data;
  const adresse = numeroRue + " " + rue;
  const client = {
    nom,
    prenom,
    email,
    telephone: telephone.replaceAll(" ", ""),
    nom_structure,
    code_postal,
    adresse,
    ville,
    pays,
  };
  try {
    return await createClient(client, signal);
  } catch (error) {
    throw error;
  }
};

const onCreateCommande = async (data, signal = null) => {
  try {
    const client = data.client.id;
    const { forfait, num, numType, zone } = data.numero;
    const commande = {
      client,
      forfait,
      num,
      numType,
      n_zone: zone,
    };
    const response = await createCommande(commande, signal);
    return response;
  } catch (error) {
    throw error;
  }
};

export { onNumerChoose, onCreateClient, onCreateCommande };
