import { useEffect, useState } from "react";

import "../../assets/css/App.css";

import Header from "../../composants/Header";
import ProgressBar from "../../composants/ProgressBar";
import Arrow from "../../composants/Arrow";
import Title from "../../composants/Title";
import Button from "../../composants/Button";
import RenvoiContainer from "../../composants/Config/RenvoiContainer";
import { useNavigate } from "react-router-dom";
import routes from "../../router/routes";

const Renvoi = () => {
  const [link, setLink] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    let localValue = sessionStorage.getItem("choix_menu");

    if (localValue === "1") {
      setLink(routes.configMandat);
    } else if (localValue === "2") {
      setLink(routes.configMenu);
    } else if (localValue === "3") {
      navigate(routes.configInfos);
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div className="Home">
      <Header lien={"/"} />
      <div className="body-config">
        <div className="container-config">
          <ProgressBar before={25} percentage={50} etape={2} />
          <Arrow lien={"step1"} />
          <Title title={"Réglage de votre standard"} />
          <RenvoiContainer />
          <Button lien={link} />
        </div>
      </div>
    </div>
  );
};

export default Renvoi;
