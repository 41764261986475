import React, { useState, useEffect } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import CardSection from "./CardSection";
import MainButton from "../inscription/MainButton";
import { toast } from "react-toastify";
import Secure3D from "../../assets/images/3d-secure.png";
import API_Routes from "../../services/API/API_Routes";

import {
  stripeAttachPaymentMethod,
  createStripeSubscription,
  stripeConfirmSetupIntent,
  stripeCreatePaymentIntent,
  createStripeCustomer,
  stripeSearchCustomers,
  createStripeVerificationSession,
} from "../../services/stripe";
import { updateCommande } from "../../services/commande";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import routes from "../../router/routes";

export default function CheckoutForm({ commande, prixForfait, clientSecret, client, numero }) {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const methods = useForm();
  const [isVerified, setIsVerified] = useState(false);

  const handleVerification = async (customerID) => {
    console.log("customerID >>>> ", customerID);
    const informationCompanyResponse = window.localStorage.getItem(
      "informationCompanyResponse"
    );
    const commandeInfos = JSON.parse(JSON.parse(informationCompanyResponse));
  
    console.log("commandeInfos >>>> ", commandeInfos);
    if (!stripe) {
      return;
    }

    try {
      // Call your backend to create the VerificationSession
      const response = await createStripeVerificationSession({
        commandeInfos,
        customerID
      });

      console.log("response >>>> ", response);

      if (!response.success) {
        throw new Error(response.message || 'Failed to create verification session');
      }

      // Show the verification modal
      const { error } = await stripe.verifyIdentity(response.client_secret);

      if (error) {
        console.error('[Verification error]', error);
        toast.error("Verification failed. Please try again.", { position: "bottom-right" });
        return false;
      } else {
        console.log('Verification submitted!');
        setIsVerified(true);
        toast.success("Verification successful!", { position: "bottom-right" });
        return true;
      }
    } catch (error) {
      console.error('Error during verification:', error);
      toast.error(error.message, { position: "bottom-right" });
      return false;
    }
  };

  const handleSubmit = async (event) => {
    if (!stripe || !elements) {
      return null;
    }
    try {
      let customerResponse;
    
      // Step 1: Check if customer already exists (based on email or other identifier)
      const existingCustomerResponse = async (client) => {
        try {
          console.log("Searching for existing customer with email:", client.email);
    
          const response = await stripeSearchCustomers({
            email: client.email,
          });
    
          console.log("Customer search response:", response);
    
          if (response?.success && response.customer) {
            console.log("Customer found:", response.customer);
            return response.customer;
          } else {
            console.log("No customer found.");
            return null;
          }
        } catch (error) {
          console.error("Error fetching customer:", error);
          throw new Error("Error fetching customer from backend");
        }
      };
    
      const existingCustomer = await existingCustomerResponse(client);
      console.log("commande >>>> ", commande);
    
      if (existingCustomer) {
        customerResponse = existingCustomer;
        await updateCommande(commande, { customerID: customerResponse.id });
        console.log("Existing customer found:", customerResponse);
      } else {
        console.log("Creating new customer...");
        customerResponse = await createStripeCustomer({ client, commande });
    
        if (!customerResponse?.id) {
          throw new Error("Failed to create Stripe customer");
        }
        console.log("New customer created:", customerResponse);
      }

      // Verify identity after customer creation
      const verificationSuccessful = await handleVerification(customerResponse.id);
      
      if (!verificationSuccessful) {
        toast.error("Identity verification is required to proceed.", { position: "bottom-right" });
        return null;
      }
    
      // Step 2: Confirm Payment
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.origin + routes.successCheckout,
        },
        redirect: "if_required",
      });
    
      if (error) {
        toast.error(error.message, {
          position: "bottom-right",
        });
        return null;
      }
    
      // Step 3: Handle Payment Intent status
      const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
    
      switch (paymentIntent.status) {
        case "requires_capture":
          try {
            // Attach Payment Method to Customer
            const attachResponse = await stripeAttachPaymentMethod({ commande });
            if (!attachResponse.success) return false;

            // Create Subscription
            const createSubscriptionResponse = await createStripeSubscription({ commande });
            if (createSubscriptionResponse?.success === true) {
              if (window.dataLayer) {
                window.dataLayer.push({
                  event: 'purchase', // Fix: 'purchase' should be a string
                  value: commande.prix,
                  id_transaction: commande.id,
                });
              }
              navigate(`${routes.successCheckout}?commande=${commande}`);
            }
            break;
          } catch (error) {
            toast.error(error?.message, { position: "bottom-right" });
            break;
          }
    
        case "processing":
          console.log("Payment is processing...");
          break;
    
        case "requires_payment_method":
          toast.error(paymentIntent.last_setup_error?.message, {
            position: "bottom-right",
          });
          break;
    
        default:
          break;
      }
    } catch (error) {
      toast.error(error.message, { position: "bottom-right" });
    }   
  };

  const ButtonConversion = () => {
    if (window.gtag) {
      window.gtag('event', 'conversion', {
        'send_to': 'AW-1000263363/22r7CKyKpPkYEMOd-9wD',
        'value': prixForfait,
        'currency': 'EUR',
        'transaction_id': commande
      });
    }
  };

  return (
    <form onSubmit={methods.handleSubmit(handleSubmit)} className="mt-4">
      <div className="border-1 border-300	p-3 my-2">
        <CardSection />
      </div>
      <div className="w-full flex align-items-center my-3">
        <span className="font-medium text-md font-italic">
          Protocole sécurisé par le système 3D Secure{" "}
        </span>
        <span className="mx-4 flex align-items-center">
          <img src={Secure3D} width={25} height={28} alt="3D secure payment" />
        </span>
      </div>
      <div className="flex relative w-full align-items-center justify-content-center lg:justify-content-end mt-6 mb-3">
        <MainButton
          label="Configurer mon standard"
          disabled={methods.formState.isSubmitting || !stripe}
          onClick={ButtonConversion}
        />
      </div>
    </form>
  );
}
