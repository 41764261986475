import { cloneElement } from "react";
import { Controller } from "react-hook-form";

const ControlledInput = ({ name, control, children }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, ref }) =>
        cloneElement(children, {
          ...field,
          ref,
        })
      }
    />
  );
};

export default ControlledInput;
