import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../utils/Store";

import "../assets/css/Button.css";
import { Link } from "react-router-dom";

const Button = (props) => {
  const { nextStep } = useContext(StoreContext);

  const [buttonStyle, setButtonStyle] = useState(true);

  const check = () => {
    if (nextStep[0] === false) {
      setButtonStyle(true);
    } else {
      setButtonStyle(false);
    }
  };

  useEffect(() => {
    check();

    // eslint-disable-next-line
  }, [nextStep[0]]);

  return (
    <Link
      to={props.lien}
      className="next-button"
      style={
        buttonStyle
          ? { pointerEvents: "none", backgroundColor: "#656565" }
          : null
      }
    >
      Suivant
    </Link>
  );
};

export default Button;
