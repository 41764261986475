import sendRequest from "../API";
import API_Routes from "../API/API_Routes";

const sendEmail = async (data) => {
  try {
    const response = await sendRequest(API_Routes.email, "Post", data);
    return response;
  } catch (err) {
    throw err;
  }
};

const sendEmailSuccessCreateAbonnement = async (data) => {
  const { client, commande, forfait } = data;
  await sendEmail({
    emailType: 1,
    email: client.email,
    nom: client.nom,
    prenom: client.prenom,
    numero_contact: client.telephone,
    structure: client.nom_structure,
    ville: client.ville,
    code_postal: client.code_postal,
    adresse: client.adresse,

    n_de_commande: commande.numero_de_commande,
    n_commandee: commande.numero_commandee,
    forfait: forfait.nom,
    commande: commande.id,
    numero_commandee: commande.numero_commandee,
    moyen_de_paiement: "Carte bancaire (Stripe)",
    prix: (parseFloat(commande.prix) * 1.2).toFixed(2),
    numero_type: "",
    domaine: process.env.REACT_APP_DOMAINE_ORIGIN,
    configToken: commande.config_token,
  });
};

const sendEmailErrorCreateAbonnement = async (data, err) => {
  const { client, forfait } = data;
  await sendEmail({
    emailType: 3,
    provenance: "Tunnel Commande",
    error: {
      route: "Create Abonnement",
      error: err,
      data: {
        total: data.prix,
        forfait,
        data,
        client,
      },
    },
  });
};

export {
  sendEmail,
  sendEmailSuccessCreateAbonnement,
  sendEmailErrorCreateAbonnement,
};
