import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../utils/Store";

import "../../assets/css/Config/Step/Step1.css";

import picto1 from "../../assets/images/config/Step1/Choix_1.svg";
import picto2 from "../../assets/images/config/Step1/Choix2.svg";
import picto3 from "../../assets/images/config/Step1/choix_3.svg";

import picto1_active from "../../assets/images/config/Step1/Choix_1_active.svg";
import picto2_active from "../../assets/images/config/Step1/Choix2_active.svg";
import picto3_active from "../../assets/images/config/Step1/choix_3_active.svg";
import { useSearchParams } from "react-router-dom";
import { InputMask } from "primereact/inputmask";

import { z } from "zod";
import { useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

const configEmailSchema = z.object({
  numero: z.string().min(1),
  email: z.string().email(),
  societe: z.string().min(1),
});

const Step1Container = () => {
  const { nextStep } = useContext(StoreContext);
  const [searchParams] = useSearchParams();
  const configToken = searchParams.get("token");
  const commandeToken = JSON.parse(
    window.localStorage.getItem("commandeToken")
  );

  const handleInputChange = (key, value) => {
    if (value === "") {
      sessionStorage.removeItem(key);
    } else {
      sessionStorage.setItem(key, value);
    }
  };

  const methods = useForm({
    values: {
      numero: configToken ? commandeToken?.numero_commandee : "",
      societe: configToken ? commandeToken?.client.nom_structure : "",
      email: configToken ? commandeToken?.client.email : "",
    },
    resolver: zodResolver(configEmailSchema),
  });

  const numero = useWatch({
    name: "numero",
    control: methods.control,
  });
  const societe = useWatch({
    name: "societe",
    control: methods.control,
  });
  const email = useWatch({
    name: "email",
    control: methods.control,
  });

  useEffect(() => {
    async function validate() {
      if (await methods.trigger()) {
        handleInputChange("email_contact", email);
        handleInputChange("nom_societe", societe);
        handleInputChange("num_buy", numero);
        nextStep[1](true);
      } else nextStep[1](false);
    }
    validate();
  }, [numero, societe, email, nextStep]);

  const [choose, setChoose] = useState(1);

  let choose1 = picto1;
  let choose2 = picto2;
  let choose3 = picto3;

  let text1 = "step1-textimg";
  let text2 = "step1-textimg";
  let text3 = "step1-textimg";

  switch (choose) {
    case 1: {
      choose1 = picto1_active;
      text1 = "step1-textimg choose";
      break;
    }
    case 2: {
      choose2 = picto2_active;
      text2 = "step1-textimg choose";
      break;
    }
    case 3: {
      choose3 = picto3_active;
      text3 = "step1-textimg choose";
      break;
    }
    default: {
      return;
    }
  }

  return (
    <div className="Step1Container">
      <div>
        <div>
          <p className="step1-title">Vos coordonnées</p>

          <div className="step1-double">
            <div className="step1-mobile">
              <p className="step1-text">Votre numéro commandé</p>
              <InputMask
                className="border-noround	"
                {...methods.register("numero")}
                disabled={Boolean(configToken)}
                id="telephone"
                type="text"
                keyfilter={"int"}
                placeholder="ex: 01 87 20 02 00"
                mask="99 99 99 99 99"
              />
            </div>
          </div>
        </div>

        <div className="step1-double">
          <div className="step1-mobile">
            <p className="step1-text">Votre nom de société contractante</p>
            <input
              {...methods.register("societe")}
              type="text"
              placeholder="ex: Color Telecom"
              disabled={Boolean(configToken)}
            />
          </div>
          <div className="step1-mobile">
            <p className="step1-text">Votre email de contact*</p>
            <input
              {...methods.register("email")}
              type="text"
              placeholder="ex: colortelecom@color.telecom"
              disabled={Boolean(configToken)}
            />
          </div>
        </div>
      </div>
      <div>
        <div style={{ paddingTop: "50px" }}></div>
        <p style={{ textAlign: "center" }} className="step1-title">
          Choisissez votre standard :
        </p>
        <div className="step1-pictoheader">
          <div
            onClick={() => {
              setChoose(1);
              handleInputChange("choix_menu", 1);
            }}
            className="step1-pictocard"
          >
            <img
              src={choose1}
              width="100%"
              height="70%"
              alt="picto1-color-telecom"
              className="step1-picto"
            />
            <p className={text1}>
              Une mise en relation vers un numéro unique, sans message et
              accueil
            </p>
          </div>
          <div
            className="step1-pictocard"
            onClick={() => {
              setChoose(2);
              handleInputChange("choix_menu", 2);
            }}
          >
            <img
              src={choose2}
              width="100%"
              height="70%"
              alt="picto2-color-telecom"
              className="step1-picto"
            />
            <p className={text2}>
              Un standard simple avec message d’accueil, attente et fermeture
            </p>
          </div>
          <div
            className="step1-pictocard"
            onClick={() => {
              setChoose(3);
              handleInputChange("choix_menu", 3);
            }}
          >
            <img
              src={choose3}
              width="100%"
              height="70%"
              alt="picto3-color-telecom"
              className="step1-picto"
            />
            <p className={text3}>
              Un standard avec menu (jusqu’à 3 choix), attente et fermeture
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step1Container;
